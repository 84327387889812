import React from 'react'

import './CardContainer.css';
import { CourseCard } from './CourseCard';
import { Box } from '@mui/material';

export const CardContainer = ({ coursesData }) => {

	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			margin={'auto'}
			flexWrap={'wrap'}
			alignItems={'center'}
			justifyContent={'center'}
			columnGap={'40px'}
			rowGap={'20px'}
			className='main-page-card-container'>
			{
				coursesData.map((course, index) => {
					return (
						<CourseCard
							key={index}
							course={course} />
					)
				})
			}
		</Box>
	)
}
