

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';


var app = initializeApp({
    type: "service_account",
    projectId: "web-marshalling-void",
    apiKey:"AIzaSyA3mfn3ZS3_J0oJuCPRsGqMgkFmErUCdP8",
    authDomain: "web-marshalling-void.firebaseapp.com",
    private_key_id: "d72109ddd7e1eb06a8dd33d84bb7c9ef557b7229",
    private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDDVHn++rDG7HA1\nFXIMoTn4l8L3m2MgcZOS+JiL37iCtTqBq5o61XW8ZVRIAQSBlZhECzVB6uWceVJp\n66+pYFRPYOLmGpJWZmnkRU9lSQZoW6vfofBhFQxfSv4crS5A37Lbv/kqTKepkMOH\nvQY7cbGL5Rcp1zoOKhoZU4uJr0ZlmNTbpDw+I1Z41OHSufskLie9zQE7ETHNPa4Y\naCz9vyTPSNMOWKsFLhJXvGk10loPqJa5khPjeRrm7dmfiy5nKYbhSRk2QidZjSQj\nJFzlhuzR1a9SiJt4RuaSTKAxwfdtcli1hhbUdbXCZcydNnWAQhW5awHz9kl6Qok4\nduE2KbaNAgMBAAECggEAVr1523gZ7oxqm7glSGSEaNeD9g7j6zFkllyc6Wh0+F5g\nV9sLh1rpiLr6vWruRFlZH8ZWZSpZwq3BolXOUQn9Bq8As6xTFdku5va8dj8CgG9N\nGOdB5urA7sRPqg5hvt9X/uv2Swl1XwBxRCL11In9jSEzsxnnpwidRGfsTD0wpsZ6\ntrJ5GnqOE4Z6JbwbYP0gEu6Ya2K2MxRt4N6RKJ1VKkLAIU0tMvJTShQhqHKEmSH+\n21b/2daVElzV3etgHsgSG2JlZN2PbxuETarr+IIMLFqtN7fqdi+H05zxJoW+SW7B\n1n3ewJeogb4GF6ipGqqnDYIV9w6UYJwV+1VAsWM4BQKBgQD7G6wp+mzZHL325Q/W\no1Oqloxyg7UwDEKCP70ZGNraDAmbEfSztf9GO4SovaBSk+lg7ZqWeYpI77cT1W1t\nObdZvhf2xAYkUHPv3UNC1M2rl4VnFbj9s2U9a6PBxjb+XPXZTR9VJJKjSJWpi7q/\n4vRJ8aVN2iulGZNXTeuuFeB8ZwKBgQDHIqCO6vy0vaUYi1q8q6JroWjwO5E6dzfg\nDe9dDTx8crDWbxnVuUnaUBGK9w4EP9juRU4G0qrKQHQAf5vrkxPIop7BrOOLXTxl\nDk1RT7bP7WZSHwRAhKRQn5ODbTcQ9mefIBAVhVyjA4qhNe29D+VgiK8FusTHqHwe\nQu+nWvnc6wKBgG0/t+K3ml3HXHqrY/2tJ3yDbW1xEgZqfxEt6/acUrMlI5S89iqt\n1vdWc7v6bGMhCRtrpKMtE9XxvUQ6yhYIU1YPF1v8IvPvsNE+AcJ01Vp/XMHhuI9g\nWn6jcyaeN1TpWukE3cnsVIQH011ldoV/l2sIYDQD5JM7ElichRpDWFynAoGBAMPJ\nbNj3exxtNQxLvxuvGJL/8L/5oRE9s+cLbGMtp2GoFevO8jSq50zI2map/tL4v12N\nMGkO6bLO6f6veGqGgbYLBVHgnRwhwEaPaD9gw5ntjGODcVfJ0hAexRc74x8/VKQk\nDwxJwcnYqo1UC3mOTnS6DnLwrZasNdQ+9Yeyr+WfAoGAaAzyoLqDYbJcn5XjSDzI\n6YirVqRln+5Hx0TLM9MxGk914s6VpOnKmA4N4ObKykJnLi+aQcjGe83qGGT9ya8N\nw3YI78CZPMtceP/lQ7pkV+K5rNPTLEKaBO5Vws3g8qKll9SHuO0aDVvzmkcSODH/\nzhbKW03nKcz1pk+EZzLeV6g=\n-----END PRIVATE KEY-----\n",
    client_email: "firebase-adminsdk-h6esg@web-marshalling-void.iam.gserviceaccount.com",
    client_id: "109596353000138783387",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-h6esg%40web-marshalling-void.iam.gserviceaccount.com",
    universe_domain: "googleapis.com",
    storageBucket: 'gs://web-marshalling-void.appspot.com'
});

export const firestore = getFirestore(app);

export const storage = getStorage(app);

export const auth = getAuth(app);
    