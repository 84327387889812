import React from 'react'

import './CourseDetailsPage.css';
import { Box, Stack } from '@mui/material';
import CourseDetailsHeader from './Components/CourseDetailsHeader/CourseDetailsHeader';
import CourseDetailsBreadcrumbs from './Components/CourseDetailsBreadcrumbs/CourseDetailsBreadcrumbs';
import CourseDetailsContainer from './Components/CourseDetailsContainer/CourseDetailsContainer';

const CourseDetailsPage = ({ courseData }) => {

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'flex-start'}
			className={'course-details-page header-padding-block'}
			width={'100%'}
			minHeight={'100vh'}
			zIndex={1}
			position={'static'}>

			<Box
				display={'flex'}
				flexDirection={'column'}
				alignItems={'center'}
				justifyContent={'center'}
				className={'course-header-container'}
				width={'100%'}
				zIndex={1}>

				<Stack
					className='header-container'
					direction={'column'}
					alignItems={'center'}
					justifyContent={'flex-start'}
					width={'90%'}
					height={'100%'}>

					<CourseDetailsBreadcrumbs
						courseData={courseData} />

					<CourseDetailsHeader
						courseData={courseData} />

				</Stack>
			</Box>


			<CourseDetailsContainer
				courseData={courseData} />

		</Box>
	)
}

export default CourseDetailsPage