import React from 'react'

import './Navbar.css';
import { Box, Typography } from '@mui/material';

const navbarItems = [
	{
		title: 'About',
		navigationId: 'AboutCourse'
	},
	{
		title: 'Content',
		navigationId: 'CourseContent'
	},
	{
		title: 'Certificate',
		navigationId: 'CourseCertificate'
	},
]

const Navbar = () => {
	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			alignItems={'center'}
			position={'sticky'}
			top={'15%'}
			zIndex={1}
			width={'96%'}
			borderRadius={'100px'}
			margin={'30px 0px 0px 0px'}
			className="content-details-navbar"
			gap>

			{
				navbarItems.map((item, index) => {
					return (
						<Typography
							key={index}
							variant='h5'
							padding={'3px 10px'}
							color={'#fff !important'}
							sx={{
								cursor: 'pointer'
							}}
							onClick={() => {
								window.scrollTo(
									{
										top: document.getElementById(item.navigationId).offsetTop - '130',
										behavior: 'smooth'
									}
								)
							}}>
							{item.title}
						</Typography>
					)
				})
			}

			{/* <Typography
				variant='h5'
				padding={'0px 10px'}
				color={'#fff !important'}
				onClick={() => {
					window.scrollTo(
						{
							top: document.getElementById('AboutCourse').offsetTop - '130',
							behavior: 'smooth'
						}
					)
				}}>
				About
			</Typography> */}

			{/* <Typography
				variant='h5'
				padding={'0px 10px'}
				color={'#fff !important'}
				onClick={() => {
					window.scrollTo(
						{
							top: document.getElementById('CourseContent').offsetTop - '130',
							behavior: 'smooth'
						}
					)
				}}>
				Content
			</Typography>

			<Typography
				variant='h5'
				padding={'0px 10px'}
				color={'#fff !important'}
				onClick={() => {
					window.scrollTo(
						{
							top: document.getElementById('CourseCertificate').offsetTop - '130',
							behavior: 'smooth'
						}
					)
				}}>
				Certificate
			</Typography> */}
		</Box >
	)
}

export default Navbar