import React from 'react'

import './LoadingPage.css';

import mvLogo from './../../Assests/images/mv_logo_no_bg.webp';

export const LoadingPage = () => {
    return (
        <div
            className='loadingPage'>

            <div
                className='loadingPage_Group'
                style={{
                    zIndex: '5'
                }}>
                <img
                    className='loadingPage_logo'
                    src={mvLogo}
                    alt='Logo'
                />
                <span
                    className='loadingPage_GroupText'>Loading...</span>
            </div>
        </div>
    )
}
