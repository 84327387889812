import React from 'react'

import './CoursesNavItem.css';

import { NavLink } from 'react-router-dom'
import { Typography } from '@mui/material';

const CoursesNavItem = ({ navItem, index }) => {
	return (
		<NavLink
			key={index}
			to={navItem.to}
			className='courses-nav-item'>
			<Typography
				variant='h5'>
				{navItem.title}
			</Typography>
		</NavLink>
	)
}

export default CoursesNavItem