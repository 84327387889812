import React, { useState } from 'react'

import './LectureListContainer.css';

import { Box, Tab, Tabs, Typography } from '@mui/material'
import TabContext from '@mui/lab/TabContext';

import LecturesList from '../LecturesList/LecturesList'
import { TabList, TabPanel } from '@mui/lab';

const LectureListContainer = ({ batch, setLectureData }) => {

	const [tabValue, setTabValue] = useState(0);

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='flex-start'
			height='100%'
			className='lectures-list-container'
			borderRadius={'10px'}
			gap>
			{/* 
			<LecturesList
				setLectureLink={setLectureLink}
				title="Open Lectures"
				lectures={batch.openLectures} /> */}

			<TabContext
				value={tabValue}>
				<TabList
					onChange={(e, newValue) => {
						setTabValue(newValue);
					}}>
					<Tab
						value={0}
						label={
							<Typography
								color={'#fff'}
								variant='h6'>
								Regular
							</Typography>
						}
					/>
					<Tab
						value={1}
						label={
							<Typography
								color={'#fff'}
								variant='h6'>
								Recorded
							</Typography>
						}
					/>
				</TabList>


				<TabPanel
					value={0}
					sx={{
						padding: '5px !important',
						width: '100% !important',
						boxSizing: 'border-box'
					}}>
					<LecturesList
						// setLectureLink={setLectureLink}
						title="Batch Lectures"
						setLectureData={setLectureData}
						lectures={batch.lectures}
						lectureOrderInvert={true} />
				</TabPanel>

				<TabPanel
					value={1}
					sx={{
						padding: '10px !important',
						width: '100% !important',
						boxSizing: 'border-box'
					}}>
					<LecturesList
						// setLectureLink={setLectureLink}
						isRecorded={true}
						title="Recorded Lectures"
						setLectureData={setLectureData}
						lectures={batch.recordedLectures} />
				</TabPanel>

			</TabContext>

		</Box>
	)
}

export default LectureListContainer