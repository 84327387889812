import React, { Fragment, useEffect, useState } from 'react'

import './Reviews.css'

// import mv_logo from '../../Assests/images/mv_logo_name.webp';
import { ReviewItem } from './ReviewItem';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../Components/Common/firebase';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

export const Reviews = () => {

	const [googleRatings, setGoogleRatings] = useState(null);

	useEffect(() => {

		async function fetchData() {

			await getDoc(doc(firestore, 'MarshallingVoid/google_ratings'))
				.then((docSnap) => {
					setGoogleRatings(docSnap.data());
				})
		}
		fetchData();
	}, [])

	if (null == googleRatings) {
		return (
			<div></div>
		)
	}

	return (
		<div
			className='reviews  block'
			style={{
				// background: "linear-gradient(to top, rgba(32, 32, 32, 0.5), rgba(0, 155, 155, 0.3) 100%)",
			}}>
			<div className='reviewsTitle'>
				Reviews
			</div>

			<Swiper
				loop='true'
				freeMode='true'
				slidesPerView={4}
				spaceBetween={40}
				pagination={{
					clickable: true
				}}
				speed='2000'
				paginationstyle={{ bottom: undefined, left: undefined, top: 35, right: "47%" }}
				modules={[Pagination, Navigation]}
			>
				{
					googleRatings.student_ratings.slice(0).reverse().map((review, index) => {
						if (review.show) {
							return (
								<SwiperSlide
									key={index}>
									<ReviewItem
										review={review} />
								</SwiperSlide>
							)
						}
						else
							return (null);
					})
				}
			</Swiper>
			{/* </div> */}
		</div>
	)
}
