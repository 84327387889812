import React from 'react'

import './EnrolledCoursesNavBar.css';

import { Box } from '@mui/material'
import CoursesNavItem from './CoursesNavItem'

const enrolledCoursesNavItems = [
	{
		title: 'All Courses',
		to: 'all-courses'
	},
	{
		title: 'Offline Courses',
		to: 'offline-courses'
	},
	{
		title: 'Online Courses',
		to: 'online-courses'
	},
	{
		title: 'On Demand Courses',
		to: 'ondemand-courses'
	}
]

const EnrolledCoursesNavBar = () => {
	return (
		<Box
			display='flex'
			flexDirection='row'
			alignItems='center'
			justifyContent='center'
			width='100%'
			className='enrolled-courses-navbar-container'
			gap>

			{
				enrolledCoursesNavItems.map((navItem, index) => {
					return (
						<CoursesNavItem
							index={index}
							navItem={navItem} />
					)
				})
			}
		</Box >
	)
}

export default EnrolledCoursesNavBar