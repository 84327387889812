import React, { useEffect, useState } from 'react'

import './UserBreadcrumbs.css';

import { Box, Breadcrumbs, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom'

import getBreadcrumbName from './../../../../Function/GetBreadcrumbName';

const UserBreadcrumbs = () => {

	const location = useLocation();
	const [title, setTitle] = useState('');

	const [breadcrumbs, setBreadcrumbs] = useState([
		<Typography
			variant='h5'
			color={'#fff'}>
			Home
		</Typography>,
		<Typography
			variant='h5'
			color={'#fff'}>
			Home
		</Typography>
	]);

	useEffect(() => {

		const name = getBreadcrumbName(location.pathname.split('/').pop());

		setTitle(name);

		const newBreadcrumbs = [...breadcrumbs];

		newBreadcrumbs.pop();
		newBreadcrumbs.push(
			<Typography
				variant='h5'
				color={'#fff'}>
				{name}
			</Typography>
		)

		setBreadcrumbs(newBreadcrumbs);

	}, [location.pathname])

	return (
		<Box
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
			padding={'0px 0px 10px 0px'}
			borderRadius={'10px'}
			zIndex={2}>

			<Stack
				direction={'column'}
				gap
				width={'100%'}
				alignItems={'center'}
				justifyContent={'center'}
				zIndex={2}
				padding={'10px'}
				className='breadcrumbs-container'>

				<Typography
					variant='h2'
					color={'#00f0f8'}>
					{title}
				</Typography>

				<Breadcrumbs
					width={'max-content'}
					separator={
						<Typography
							variant='h5'
							color={'#999'}>
							{'>'}
						</Typography>
					}
					aria-label='breadcrumbs'>
					{breadcrumbs}
				</Breadcrumbs>

			</Stack>
		</Box>
	)
}

export default UserBreadcrumbs