import React, { useEffect, useState } from 'react'

import './EnrolledCourses.css';

import { AppBar, Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../Components/Common/firebase';
import EnrolledCoursesList from './EnrolledCoursesList';
import EnrolledCoursesNavBar from './EnrolledCoursesNavBar';
import { Outlet, Route, Routes } from 'react-router-dom';

const EnrolledCourses = ({ user, coursesData }) => {

	const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userdata')))

	useEffect(() => {

		if (null === userData && null !== user) {
			getDoc(doc(firestore, 'Users/' + user.id))
				.then((docSnapshot) => {
					setUserData(docSnapshot.data());
					var userData = docSnapshot.data();
					localStorage.setItem('userdata', JSON.stringify(userData));
				})
		}

	}, [localStorage.getItem('userdata'), user, null === userData]);

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			zIndex={'5'}
			className='enrolled-courses-wrapper'>

			<Typography
				variant='h4'
				sx={{
					padding: '10px'
				}}>
				Enrolled Courses
			</Typography>

			<Divider
				sx={{
					borderColor: '#888',
					borderWidth: '1px',
					width: '100%'
				}} />

			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='space-evenly'
				width='100%'
				padding={'10px 0px'}
				className='enrolled-courses-container'
				gap>

				<EnrolledCoursesNavBar />

				{/* <AppBar
					position='static'>
					<Tabs>
						<Tab
							label='One' />
						<Tab
							label='One' />
						<Tab
							label='One' />
						<Tab
							label='One' />
					</Tabs>
				</AppBar> */}

				<Outlet />

			</Box>
		</Box>
	)
}

export default EnrolledCourses