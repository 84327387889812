import React, { useEffect, useState } from 'react'

import './CourseCard.css';
import { Alert, Box, Card, CardContent, CardMedia, Collapse, IconButton, Snackbar, Stack, Typography } from '@mui/material';

import PersonAddDisabledRoundedIcon from '@mui/icons-material/PersonAddDisabledRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';

// import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
// import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded';
// import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
// import LiveTvRoundedIcon from '@mui/icons-material/LiveTvRounded';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { firestore } from '../../../../../../Components/Common/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import axios from 'axios'
import CountdownTimer from '../../../../../../Components/CountdownTimer/CountdownTimer';

const days = [
	'Mon',
	'Tue',
	'Wed',
	'Thur',
	'Fri',
	'Sat',
	'Sun'
];

function getDaysName(firstDay, nrDays) {
	var dayNames = []
	for (var le = firstDay; le <= (nrDays - 1 + firstDay); ++le) {

		if (firstDay !== le)
			dayNames += " - ";

		dayNames += days[le];
	}
	return (dayNames);
}

function getHour(date) {
	if (date.getHours() > 12) {

		return ((date.getHours() % 12) + ":00 PM");
	}
	else {
		return (date.getHours() + ":00 AM");
	}
}

export const CourseCard = ({ courseData }) => {

	const [courseModeId] = useState(0);
	const [userData, setUserData] = useState(0);

	const firstDay = (new Date(courseData.startDate).getDay() === 0 ? 6 : new Date(courseData.startDate).getDay() - 1);
	const dayNames = getDaysName(firstDay, courseData.nrDays);

	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	function checkUserAdmission() {
		var userData;
		const user = JSON.parse(localStorage.getItem('user'));
		return (getDoc(doc(firestore, 'Users/' + user.id))
			.then((docRef) => {
				userData = docRef.data();
				setUserData(userData);
			})
			.then(() => {
				return (undefined === userData.classroom_online_courses_enrolled
					||
					-1 === userData.classroom_online_courses_enrolled.indexOf(courseData.batch)
				)
			})
		)
	}

	function handlePaymentSuccess(response, order_id) {

		var userData;
		const user = JSON.parse(localStorage.getItem('user'));
		getDoc(doc(firestore, 'Users/' + user.id))
			.then((docRef) => {
				userData = docRef.data();
			})
			.then(() => {

				var data;
				if (undefined === userData.classroom_online_courses_enrolled) {
					data = {
						classroom_online_courses_enrolled: [courseData.batch],
						[courseData.batch]: {
							hasAccess: true,
							payment_id: response.razorpay_payment_id,
							order_id: order_id,
							enrolledTime: new Date().toString(),
						}
					}
				} else {
					if (-1 === userData.classroom_online_courses_enrolled.indexOf(courseData.batch)) {
						data = {
							classroom_online_courses_enrolled: [...userData.classroom_online_courses_enrolled, courseData.batch],
							[courseData.batch]: {
								hasAccess: true,
								payment_id: response.razorpay_payment_id,
								order_id: order_id,
								enrolledTime: new Date().toString(),
							}
						}
					}
				}

				setDoc(doc(firestore, 'Users/' + user.id), data, { merge: true });
				alert(`Payment Successful. Payment ID: ${response.razorpay_payment_id} & ${order_id}`)
			});
	}


	const handlePayment = async () => {

		const orderUrl = 'https://createorder-ge6xbfeo6q-uc.a.run.app';
		// const orderUrl = 'http://localhost:5000/web-marshalling-void/us-central1/createOrder';
		const response = await axios.post(orderUrl, {
			data: {
				amount: courseData.fees * (100 - courseData.discount) / 100,
				currency: 'INR',
				notes: {
					address: 'Class Address',
				},
			}
		});

		const { id: order_id, amount: order_amount } = response.data.result;
		const user = JSON.parse(localStorage.getItem('user'));

		const options = {
			key: "rzp_live_hvtu2N3ct95A8c",
			// key: "rzp_test_yJq3Ye05thBC19",
			amount: order_amount,
			currency: response.data.currency,
			name: 'Marshalling Void',
			description: 'Test Transaction',
			id: order_id,
			handler: function (response) {
				handlePaymentSuccess(response, order_id)
			},
			prefill: {
				name: (user.given_name + " " + user.family_name),
				email: user.email,
				contant: '9999999999'
			},
			theme: {
				color: '#0077ff',
			}
		};

		const rzp1 = new window.Razorpay(options);
		rzp1.open();
	}

	return (
		<div
			className='course-card-container'>
			<Card
				className='course-card'>
				{
					"" !== courseData.videoLink
					&&
					<CardMedia
						component="video"
						src={courseData.videoLink}
						alt="Course Poster"
					/>
				}
				<div
					className='card-content'>
					<Typography
						variant='h4'
						fontFamily={"inherit"}
						sx={{
							color: '#ff0',
							fontWeight: '200'
						}}>
						₹{courseData.fees * (100 - courseData.discount) / 100}
					</Typography>
					<Typography
						variant='h5'
						fontFamily={"inherit"}
						sx={{
							color: '#aaa',
							fontWeight: '100',
							textDecoration: 'line-through'
						}}
					>
						₹{courseData.fees}
					</Typography><Typography
						variant='h4'
						fontFamily={"inherit"}
						sx={{
							color: '#FFA500',
							filter: 'drop-shadow(0px 0px 4px #FFAF0077)'
						}}
					>
						{courseData.discount}% OFF
					</Typography>
				</div>

				<Collapse
					in={0 === courseModeId || true}
				>
					<CardContent
						sx={{
							padding: '0px !important',
						}}>

						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}>
							<Stack
								direction={'row'}
								alignItems={'center'}>
								<AccessTimeRoundedIcon
									sx={{
										color: '#fff !important'
									}}
								/>
								<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Time</Typography>
							</Stack>
							<Stack
								direction={'row'}
								alignItems={'center'}>
								<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{getHour(new Date(courseData.fromTime))}</Typography>
								<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >to</Typography>
								<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{getHour(new Date(courseData.toTime))}</Typography>
							</Stack>
						</Stack>

						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}>

							<Stack
								direction={'row'}
								alignItems={'center'}>
								<TodayRoundedIcon
									sx={{
										color: '#fff !important'
									}}
								/>
								<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Days</Typography>
							</Stack>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{dayNames}</Typography>
						</Stack>

						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}>
							<Stack
								direction={'row'}
								alignItems={'center'}>
								<DateRangeRoundedIcon
									sx={{
										color: '#fff !important'
									}}
								/>
								<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Starts</Typography>
							</Stack>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{new Date(courseData.startDate).toDateString()}</Typography>
						</Stack>

						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}>
							<Stack
								direction={'row'}
								alignItems={'center'}>
								<CalendarMonthRoundedIcon
									sx={{
										color: '#fff !important'
									}}
								/>
								<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Duration</Typography>
							</Stack>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{courseData.nrMonths} Months</Typography>
						</Stack>

						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}>

							<Stack
								direction={'row'}
								alignItems={'center'}>
								<LanguageRoundedIcon
									sx={{
										color: '#fff !important'
									}}
								/>
								<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Language</Typography>
							</Stack>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >Marathi</Typography>
						</Stack>

						{/* <Stack
							direction={'column'}
							justifyContent={'space-evenly'}
							alignItems={'center'}>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} startDecorator={<TaskAltRoundedIcon />} variant='h5' >{courseData.coursePrerequisite}</Typography>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} startDecorator={<DataObjectRoundedIcon />} variant='h5' >{courseData.courseProgrammingLanguage}</Typography>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} startDecorator={<InfoOutlinedIcon />} variant='h5' >{courseData.included}</Typography>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} startDecorator={<LiveTvRoundedIcon />} variant='h5' >Live Lectures</Typography>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} startDecorator={<WorkOutlineRoundedIcon />} variant='h5' >Placement Mentorship</Typography>
						</Stack> */}

					</CardContent>
				</Collapse>

				<Box
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'center'}
					alignItems={'flex-start'}
					gap>
					<Typography
						variant='h5'
						sx={{
							color: '#00ff00'
						}}>
						Admission Till
					</Typography>
				</Box>

				<CountdownTimer
					toDateTime={new Date(courseData.admissionTillDate)}
				/>

				{
					courseData.courseIsAdmissionsOpen
						?
						<div
							className='card-content'>


							<IconButton
								className='card-online-button'
								onClick={() => {

									// if (0 === courseModeId || 1 === courseModeId)
									// 	setCourseModeId(2)
									// else
									// 	setCourseModeId(0)

									if (null === localStorage.getItem('user')) {
										const button = document.getElementById('signup_button')
										button.classList.add('navbar_button_highlight')
									} else {
										checkUserAdmission()
											.then((value) => {
												if (value)
													handlePayment();
												else {
													setOpen(true);
												}
											})
									}
								}}
							>
								<HowToRegOutlinedIcon />
								Admission
							</IconButton>
						</div>
						:
						<span
							className={courseData.courseIsAdmissionsOpen ? 'admission_open admission_line' : 'admission_closed admission_line'} >
							{courseData.courseIsAdmissionsOpen ? <HowToRegRoundedIcon /> : <PersonAddDisabledRoundedIcon />}
							{courseData.courseIsAdmissionsOpen ? 'Admission Open' : 'Admission Closed'}
						</span>
				}

				{/* {
					false
					&&
					<Collapse
						in={0 < courseModeId && courseData.courseIsAdmissionsOpen}
						className='payment_card_container' >
						<Box
							className={'payment_card' + (courseModeId === 1 ? ' payment_card_green' : (courseModeId === 2 ? ' payment_card_blue' : ''))}>
							<img
								className='payment_card_qr_image'
								src={insta_color}
								alt='Payment QR Code' />

							<Typography
								className='upi_id'>
								abcdefgh@rost
							</Typography>

							<Typography
								className='payment_heading'>
								Upload Payment Screenshot
							</Typography>

							<Box
								className='payment_screenshot_upload_container'>
								<Button
									className='payment_screenshot_choosefile_button'
									onClick={() => {
										fileUploadRef.current.click()
									}}>
									<span>Choose File</span>
								</Button>

								{
									'' !== screenshotFileName
									&&
									< Typography
										className='payment_screentshot_filename'
										variant='h5'>
										{screenshotFileName}
									</Typography>
								}

								{
									'' !== screenshotFileName
									&&
									<IconButton
										className='payment_screenshot_upload_button'
										onClick={() => {
											uploadScreenShot();
										}}>
										<CloudUploadOutlinedIcon />
										Upload
									</IconButton>
								}
							</Box>

							<input
								ref={fileUploadRef}
								type='file'
								title=''
								value=''
								hidden
								onChange={handleChange}
							/>
						</Box>
					</Collapse>
				} */}
			</Card >

			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}>

				<Alert
					onClose={handleClose}
					severity="success"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Your admission is already Done
				</Alert>
			</Snackbar>
		</div >
	)

}

