import React from 'react'

import { IconButton, Stack, Typography } from '@mui/material';

import SmartDisplayRoundedIcon from '@mui/icons-material/SmartDisplayRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

import { FaCode } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { SiZoom } from "react-icons/si";
import { FaRegPlayCircle } from "react-icons/fa";

function LectureIcon({ lectureLink }) {

	if (undefined === lectureLink || '' === lectureLink) {
		return (
			<SmartDisplayRoundedIcon
				sx={{
					fontSize: '1.6rem',
					color: '#fff'
				}} />
		)
	}
	else if (lectureLink.includes('youtube')) {
		return (
			<FaYoutube
				className={'MuiSvgIcon-root ' + (undefined === lectureLink || '' === lectureLink ? 'MuiTypography-disabled' : '')}
				color='#FF0000'
				fontSize={'1.6rem'} />
		)
	}
	else if (lectureLink.includes('zoom') && !lectureLink.includes('rec')) {
		return (
			<SiZoom
				className={'MuiSvgIcon-root ' + (undefined === lectureLink || '' === lectureLink ? 'MuiTypography-disabled' : '')}
				color='#2d8cff'
				fontSize={'1.6rem'} />
		)
	}
	else {
		return (
			<FaRegPlayCircle
				className={'MuiSvgIcon-root ' + (undefined === lectureLink || '' === lectureLink ? 'MuiTypography-disabled' : '')}
				color='#ff9fff'
				fontSize={'1.6rem'} />
		)
	}
}


const LectureDataButtons = ({ lecture }) => {

	if (lecture.lectureIsHoliday)
		return (<></>);

	return (
		<Stack
			direction={'row'}
			gap>
			{
				lecture.lectureLink
				&&
				'' !== lecture.lectureLink
				&&
				<Stack
					alignItems={'center'}
					justifyContent={'space-evenly'}>
					<IconButton
						sx={{
							padding: '1px !important',
							height: '1.6rem !important',
							width: '1.6rem !important',
						}}
						disabled={undefined === lecture.lectureLink || '' === lecture.lectureLink}
						onClick={() => {
							if (lecture.lectureLink.includes('zoom')) {
								window.open(lecture.lectureLink, '_blank')
							}
						}}>

						<LectureIcon
							lectureLink={lecture.lectureLink} />

					</IconButton>

					<Typography
						variant='caption'
						sx={{
							color: '#fff',
							padding: '1px !important'
						}}>
						Lecture
					</Typography>
				</Stack>
			}

			{
				lecture.lectureNotesLink
				&&
				'' !== lecture.lectureNotesLink
				&&
				<Stack
					alignItems={'center'}
					justifyContent={'space-evenly'}>
					<IconButton
						sx={{
							padding: '1px !important',
							height: '1.6rem !important',
							width: '1.6rem !important',
						}}
						onClick={() => {
							window.open(lecture.lectureNotesLink, '_blank');
						}}>
						<DescriptionRoundedIcon
							sx={{
								fontSize: '1.6rem',
								color: '#fff'
							}} />
					</IconButton>
					<Typography
						className={(undefined === lecture.lectureNotesLink || '' === lecture.lectureNotesLink ? 'MuiTypography-disabled' : '')}
						variant='caption'
						sx={{
							color: '#fff',
							padding: '1px !important'
						}}>
						Notes
					</Typography>
				</Stack>
			}

			{
				lecture.lectureAssignment
				&&
				'' !== lecture.lectureAssignment
				&&
				<Stack
					alignItems={'center'}
					justifyContent={'space-evenly'}>
					<IconButton
						sx={{
							padding: '1px !important',
							height: '1.6rem !important',
							width: '1.6rem !important',
						}}
						onClick={() => {
							window.open(lecture.lectureAssignment, '_blank');
						}}>
						<FaCode
							color='#00ffff'
							fontSize={'1.6rem'} />
					</IconButton>
					<Typography
						// className={(undefined === lecture.lectureAssignment || '' === lecture.lectureAssignment ? 'MuiTypography-disabled' : '')}
						variant='caption'
						sx={{
							color: '#00ffff',
							padding: '1px !important'
						}}>
						Assignment
					</Typography>
				</Stack>
			}

		</Stack >
	)
}

export default LectureDataButtons