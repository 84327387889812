import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'

import './LectureAttendateContainer.css';

function getOnlyTime(value) {
	const parts = value.split(' ');

	return (parts[1] + ' ' + parts[2]);
}

function getPercent(partValue, completeValue) {

	console.log(completeValue)

	return (parseInt((partValue * 100) / completeValue));
}

function getEmojiFromPercent(value) {

	if (80 < value) {
		return (<>&#128513;</>)
	} else if (60 < value && value <= 80) {
		return (<>&#128578;</>)
	} else if (40 < value && value <= 60) {
		return (<>&#128528;</>)
	} else {
		return (<>&#128542;</>)
	}
}

function getMVID(user) {
	return ('mv_' + user.mv_id);
}

const LectureAttendanceContainer = ({ lecture }) => {

	const [user] = useState(JSON.parse(localStorage.getItem('userdata')));

	const [totalAttendedMins, setTotalAttendedMins] = useState(0);
	const [lectureTotalDuration, setLectureTotalDuration] = useState(null);

	useEffect(() => {
		if (lecture.attendance
			&&
			Object.keys(lecture.attendance).includes(getMVID(user))
			&&
			lecture.attendance[getMVID(user)].attendance) {

			var totalDuration = 0;

			lecture.attendance[getMVID(user)].attendance.forEach((item) => {
				totalDuration += parseInt(item.duration);
			})

			setTotalAttendedMins(totalDuration);
		}
		else {
			setTotalAttendedMins(0);
		}

		if (lecture.details)
			setLectureTotalDuration(lecture.details.duration);


	}, [lecture, user])

	if (!lecture.attendance ||
		!lecture.details)
		return (<></>)

	if (!lecture.attendance[getMVID(user)]) {
		return (
			<Typography
				variant='h5'>
				You Were Absent &#128557;
			</Typography>
		)
	}

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			width={'100%'}
			gap
			className='lecture-attendance-container'>

			<Typography
				color={'#ccc'}
				variant='h5'>
				Your Attendance is {getEmojiFromPercent(getPercent(totalAttendedMins, lectureTotalDuration))}
			</Typography>

			<Stack
				direction={'row'}
				alignItems={'flex-end'}
				justifyContent={'space-evenly'}
				gap>

				<Typography
					variant='h6'>
					{totalAttendedMins} mins
				</Typography>

				<Typography
					color={'#888'}
					variant='h6'>
					/
				</Typography>

				<Typography
					color={'#888'}
					variant='subtitle1'>
					{lectureTotalDuration} mins
				</Typography>

				<Typography
					variant='h6'>
					( {getPercent(totalAttendedMins, lectureTotalDuration)}% )
				</Typography>

			</Stack>

			<TableContainer
				className='lecture-attendance-table-container'
				component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								className='table-cell-jointime'
								align='center'
								sx={{
									padding: '2px !important'
								}}>
								Join Time
							</TableCell>
							<TableCell
								className='table-cell-leavetime'
								align='center'
								sx={{
									padding: '2px !important'
								}}>
								Leave Time
							</TableCell>
							<TableCell
								className='table-cell-duration'
								align='center'
								sx={{
									padding: '2px !important'
								}}>
								Duration (mins)
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lecture.attendance
							&&
							Object.keys(lecture.attendance).includes(getMVID(user))
							&&
							lecture.attendance[getMVID(user)].attendance.map((item, index) => {

								return (
									<TableRow
										key={index}>
										<TableCell
											className='table-cell-jointime'
											align='center'>
											{getOnlyTime(item.joinTime)}
										</TableCell>
										<TableCell
											className='table-cell-leavetime'
											align='center'>
											{getOnlyTime(item.leaveTime)}
										</TableCell>
										<TableCell
											className='table-cell-duration'
											align='center'>
											{item.duration}
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Box >
	)
}

export default LectureAttendanceContainer