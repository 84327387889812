import React from 'react'

import { Box } from '@mui/material'

const YouTubeLecturePlayer = ({ lectureLink }) => {
	return (
		<Box
			component='div'
			display='flex'
			flexDirection={'row'}
			justifyContent={'center'}
			sx={{
				aspectRatio: '16/9',
			}}
			borderRadius={'10px'}
			className='videoplayer-box'>

			<iframe
				width="100%"
				style={{
					aspectRatio: '16/9'
				}}
				src={lectureLink}
				title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe>
		</Box>
	)
}

export default YouTubeLecturePlayer