import React from 'react'

import './WhyChoseMarshallingVoid.css';

const points = [
	{
		title: 'Expert Instruction by Mayur Tadekar',
		message: 'Learn directly from Tadekar Mayur Sir, a seasoned industry professional with years of experience and a passion for teaching.',
	},
	{
		title: 'Comprehensive Curriculum',
		message: 'Our courses cover everything from basic programming to advanced software development, ensuring you gain a thorough understanding of key concepts.',
	},
	{
		title: 'Real-World Projects',
		message: 'Apply your knowledge through hands-on projects that simulate real industry scenarios, giving you practical experience.',
	},
	{
		title: 'Career Support',
		message: 'Benefit from our extensive network and career counseling services to help you land your dream job.',
	},
	{
		title: 'Community and Networking',
		message: 'Join a thriving community of like-minded peers and professionals, fostering valuable connections and support.',

	},
	{
		title: 'Continual Learning',
		message: 'Stay updated with the latest industry trends and technologies through our continual learning resources and workshops.',

	}
];

export const WhyChoseMarshallingVoid = () => {
	return (
		<div className="container block">

			<h2 className="heading">Why Choose Marshalling Void?</h2>

			<div className="card-container">
				{
					points.map((point, index) => {
						return (
							<div
								key={index}
								className="card">
								<span>{index + 1}</span>
								<h3>{point.title}</h3>
								<p>{point.message}</p>
							</div>
						);
					})
				}
			</div>
		</div>
	)
}
