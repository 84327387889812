
export function getDoubleDigit(value) {
	return (value < 10 ? '0' + value : value);
}

export function getHours(value) {
	return (value > 12 ? value % 12 : value);
}

export function getMeridiem(value) {
	return (value < 12 ? 'AM' : 'PM');
}

export function getTime(dateTimeStr) {
	var date = new Date(dateTimeStr);
	return (`${getDoubleDigit(getHours(date.getHours()))}:${getDoubleDigit(getHours(date.getMinutes()))} ${getMeridiem(date.getHours())}`);
}

// { getTime, getMeridiem, getHours, getDoubleDigit }; 
