import React, { useEffect, useState } from 'react'

import './EnrolledCoursesList.css';
import { Box } from '@mui/material';
import CourseCardItem from './CourseCardItem';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../Components/Common/firebase';

function getCoursesToShow(user, filter) {
	var courseKeys = [];

	if ((1 === filter || 2 === filter) && undefined !== user.classroom_offline_courses_enrolled) {
		courseKeys = [...courseKeys, ...user.classroom_offline_courses_enrolled]
	}

	if ((1 === filter || 3 === filter) && undefined !== user.classroom_online_courses_enrolled) {
		courseKeys = [...courseKeys, ...user.classroom_online_courses_enrolled]
	}

	if ((1 === filter || 4 === filter) && undefined !== user.ondemand_courses_enrolled) {
		courseKeys = [...courseKeys, ...user.ondemand_courses_enrolled]
	}

	return (courseKeys);
}

const EnrolledCoursesList = ({ filter, user, coursesData }) => {

	const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userdata')))

	useEffect(() => {

		if (null === userData && null !== user) {
			getDoc(doc(firestore, 'Users/' + user.id))
				.then((docSnapshot) => {
					setUserData(docSnapshot.data());
					var userData = docSnapshot.data();
					localStorage.setItem('userdata', JSON.stringify(userData));
				})
		}

	}, [user, userData,]);

	return (
		<Box
			display='flex'
			flexDirection='row'
			flexWrap={'wrap'}
			alignItems='center'
			justifyContent='center'
			width='90%'
			gap
			className='enrolled-courses-list'>

			{
				getCoursesToShow(userData, filter).map((key, index) => {
					return (
						<CourseCardItem
							key={index}
							index={index}
							courseData={coursesData[key.split('_')[0]]} />
					)
				})
			}

		</Box>
	)
}

export default EnrolledCoursesList