import React from 'react'

import './CourseCertificate.css';

export const CourseCertificate = ({ certificate }) => {
	return (
		<div
			id="CourseCertificate"
			className='course-certificate'>

			<div
				className='course-certificate-container'>
				<h2
					className='container-title'>
					Certificate
				</h2>
				<img
					src={certificate}
					alt='Certificate for course'
				/>
			</div>
		</div>
	)
}
