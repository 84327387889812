import React, { useEffect, useRef } from 'react'

import './LectureVideoPlayer.css';

import { Box } from '@mui/material'

const LectureVideoPlayer = ({ lectureLink }) => {

	const playerRef = useRef(null);

	useEffect(() => {

		const player = playerRef.current;
		player.load();

		const handleLoadedMetadata = () => {
			// console.log('Video metadata loaded');
		};

		const handleError = (e) => {
			console.error('Error loading video', e);
		};

		player.addEventListener('loadedmetadata', handleLoadedMetadata);
		player.addEventListener('error', handleError);

		return () => {
			player.removeEventListener('loadedmetadata', handleLoadedMetadata);
			player.removeEventListener('error', handleError);
		};

	}, [lectureLink])

	return (
		<Box
			component='div'
			display='flex'
			flexDirection={'row'}
			justifyContent={'center'}
			sx={{
				aspectRatio: '16/9',
			}}
			borderRadius={'10px'}
			className='videoplayer-box'>

			<video
				ref={playerRef}
				controls
				muted // Ensure the video is muted
				disablePictureInPicture={true}
				disableRemotePlayback={true}
				controlsList='nodownload noplaybackrate'
				playsInline
				loop
				tabIndex="-1" // Adding tabindex
				role="application" // Adding role
				style={{
					width: '100%',
					height: 'auto',
					borderRadius: 'inherit !important'
				}}
			>
				<source
					src={lectureLink}
					type="video/mp4" />
			</video>
		</Box>
	)
}

export default LectureVideoPlayer