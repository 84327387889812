import React, { useState } from 'react';

import './Navbar.css';
import logo from '../../Assests/images/mv_logo_name.webp';

import { NavLink } from 'react-router-dom';
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';

import { useGoogleLogin } from '@react-oauth/google';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../Common/firebase';

import ListRoundedIcon from '@mui/icons-material/ListRounded';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';

export const Navbar = ({ user, setUser }) => {

	const [showUserDropDown, setShowUserDropdown] = useState(false);

	const login = useGoogleLogin({
		clientId: '851777582489-unsfve0umc180bnspc985kdborn51vmc.apps.googleusercontent.com',
		cookiePolicy: 'single_host_origin',
		scope: 'profile email',
		onSuccess: async (codeResponce) => {
			const accessToken = codeResponce.access_token;

			try {
				const res = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				if (!res.ok) {
					throw new Error('Failed to fetch user profile');
				}

				var profile;
				res.text()
					.then((data) => {
						profile = JSON.parse(data);
						setUser(profile); // Store user profile data
						localStorage.setItem('user', JSON.stringify(profile)); // Save to localStorage

						var databaseData = {
							first_name: profile.given_name,
							last_name: profile.family_name,
							uid: profile.id,
							user_name: profile.name,
							mailid: profile.email,
							designation: 'student',
							signed_up: new Date().toString()
						}

						getDoc(doc(firestore, 'Users/' + profile.id))
							.then((docRef) => {
								if (docRef.exists()) {
									localStorage.setItem('userdata', JSON.stringify(docRef.data()));
									if (!docRef.data().hasOwnProperty('signed_up')) {
										databaseData = {
											...databaseData,
											signed_up: new Date().toString()
										}
										setDoc(doc(firestore, 'Users/' + profile.id), databaseData);
									}
								} else {
									setDoc(doc(firestore, 'Users/' + profile.id), databaseData);
								}
							})

					})
			} catch (error) {
				console.error('Error fetching user profile:', error.message);
			}
		},
		onError: (error) => {
			console.log(error);
		}
	})

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div
			className='nav-bar'>
			<nav>
				{
				/* <div className='navbar_Menu' onClick={() => {
					setMenuOpen(!menuOpen);
				}}>
					<div></div>
					<div></div>
					<div></div>
				</div> */}

				{/* <IconButton
					className='menu-icon-button'
					id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}>
					<ListRoundedIcon />
				</IconButton>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<NavLink
						className='menu-item'
						to='/'
						onClick={() => {
							window.scrollTo(
								{
									top: 0,
									behavior: 'smooth'
								}
							)
						}}
					>
						<MenuItem onClick={handleClose}>Home</MenuItem>
					</NavLink>

					<NavLink
						className='menu-item'
						to='/courses'
						onClick={() => {
							window.scrollTo(
								{
									top: 0,
									behavior: 'smooth'
								}
							)
						}}>
						<MenuItem onClick={handleClose}>Courses</MenuItem>
					</NavLink>


					<MenuItem
						onClick={() => {
							handleClose()
							window.scrollTo(
								{
									top: document.getElementById('footer').offsetTop,
									behavior: 'smooth'
								}
							)
						}}
					>
						Contact Us
					</MenuItem>

					<NavLink
						className='menu-item'
						to='/about-us'
						onClick={() => {
							window.scrollTo(
								{
									top: 0,
									behavior: 'smooth'
								}
							)
						}}>
						<MenuItem onClick={handleClose}>About</MenuItem>
					</NavLink>
				</Menu> */
				}

				<NavLink className='navbar_titleLink' to='/'>
					<div className='navbar_Title' id='navbar'>
						<img className='navbar_Logo' src={logo} alt='' />
					</div>
				</NavLink>
				<ul className='navbar_Open'>
					<li>
						<NavLink
							className='menu-item'
							to='/'
							onClick={() => {
								window.scrollTo(
									{
										top: 0,
										behavior: 'smooth'
									}
								)
							}}
						>
							<IconButton
								className='navbar_Button'
								aria-label="expand"
								style={{
									padding: 'none !important',
								}}>
								Home
							</IconButton>
						</NavLink>
					</li>
					<li>
						<NavLink
							className='menu-item'
							to='/courses'
							onClick={() => {
								window.scrollTo(
									{
										top: 0,
										behavior: 'smooth'
									}
								)
							}}>
							<IconButton
								className='navbar_Button'
								aria-label="expand"
								style={{
									padding: 'none !important',
								}}>
								Courses
							</IconButton>
						</NavLink>
					</li>
					{/* <li>
						<IconButton
							className='navbar_Button'
							aria-label="expand"
							style={{
								padding: 'none !important',
							}}
							onClick={() => {
								window.scrollTo(
									{
										top: document.getElementById('footer').offsetTop,
										behavior: 'smooth'
									}
								)
							}}
						>
							Contact Us
						</IconButton>
					</li> */}
					<li>
						<NavLink
							className='menu-item'
							to='/about-us'
							onClick={() => {
								window.scrollTo(
									{
										top: 0,
										behavior: 'smooth'
									}
								)
							}}>
							<IconButton
								className='navbar_Button'
								aria-label="expand"
								style={{
									padding: 'none !important',
								}}>
								About
							</IconButton>
						</NavLink>
					</li>
				</ul>
				{
					user ?

						<IconButton
							className="menu-icon-button"
							onClick={(e) => {
								handleClick(e);
								setShowUserDropdown(true)
							}}
						>
							<MenuOpenRoundedIcon
								sx={{
									color: 'white',
									fontSize: '2rem'
								}} />
						</IconButton>

						:
						<IconButton
							id='signup_button'
							className='navbar_Button'
							aria-label="expand"
							style={{
								padding: 'none !important',
							}}
							onClick={() => {
								const button = document.getElementById('signup_button')
								button.classList.remove('navbar_button_highlight')
							}}
							onMouseDown={() => login()}
						>
							<LoginRoundedIcon
								style={
									{
										padding: '0px 5px',
										width: '2rem',
										height: '2rem',
									}
								}
							/>
							Sign In
						</IconButton>
				}
			</nav>

			<DropdownMenu
				anchorEl={anchorEl}
				user={user}
				setUser={setUser}
				showDropdown={showUserDropDown}
				setShowDropdown={() => {
					handleClose();
					setShowUserDropdown(false);
				}}
			/>

		</div >
	)
}
