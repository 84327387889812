import React, { useEffect, useState } from 'react'

import './Profile.css';

import { Box, Divider, IconButton, Snackbar, Stack, Typography } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../Components/Common/firebase';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import copy from "copy-to-clipboard";
import { faL } from '@fortawesome/free-solid-svg-icons';

const Profile = ({ user }) => {

	const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userdata')))

	const [snackbarOpen, setSnackbarOpen] = useState(false);

	useEffect(() => {
		if (null === userData && null !== user) {
			getDoc(doc(firestore, 'Users/' + user.id))
				.then((docSnapshot) => {
					setUserData(docSnapshot.data());
					var userData = docSnapshot.data();
					localStorage.setItem('userdata', JSON.stringify(userData));
				})
		}
	}, [localStorage.getItem('userdata'), user, null == userData]);

	const handleCopyToClipboard = () => {
		var isCopied = copy('MV_' + userData.mv_id + '_' + userData.first_name + '_' + userData.last_name);

		if (isCopied) {
			setSnackbarOpen(true);
		}
	}

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	}

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			className='profile-wrapper'>

			<Snackbar
				open={snackbarOpen}
				onClose={handleSnackbarClose}
				message="MVID Copied Successfully"
				key={'top left'}
			/>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='space-evenly'
				width='100%'
				className='profile-container'
				gap={'10px'}>

				<Typography
					variant='h4'
					sx={{
						padding: '10px'
					}}>
					Profile
				</Typography>

				<Divider
					sx={{
						borderColor: '#888',
						borderWidth: '1px',
						width: '100%'
					}} />

				<Box
					display='flex'
					flexDirection='column'
					alignItems='flex-start'
					justifyContent='space-evenly'
					width='100%'
					className='profile-user-info'
					padding={'10px'}
					borderRadius={'20px'}
					gap>
					<Stack
						direction={'row'}
						alignItems={'center'}
						gap>
						<Typography
							className='lecture_id'
							variant='h5'>
							MV ID:
						</Typography>
						{/* 
						<Typography
							variant='h5'>
							{userData.mv_id}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						alignItems={'center'}
						gap>
						<Typography
							className='lecture_id'
							variant='h5'
							sx={{
								fontWeight: '600 !important'
							}}>
							Lecture ID:
						</Typography> */}

						<Typography
							variant='h5'
							className='lecture_id'
							sx={{
								fontWeight: '600 !important'
							}}>
							MV_{userData.mv_id}_{userData.first_name}_{userData.last_name}
						</Typography>
						<IconButton
							sx={{
								cursor: 'pointer'
							}}
							onClick={handleCopyToClipboard}
							className='lecture_id'>
							<ContentCopyRoundedIcon />
						</IconButton>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							SignedUp On:
						</Typography>

						<Typography
							variant='h5'>
							{new Date(userData.signed_up).toDateString()} {new Date(userData.signed_up).getHours()}:{new Date(userData.signed_up).getMinutes()}:{new Date(userData.signed_up).getSeconds()}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							First Name:
						</Typography>
						<Typography
							variant='h5'>
							{userData.first_name}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							Last Name:
						</Typography>
						<Typography
							variant='h5'>
							{userData.last_name}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							User Name:
						</Typography>
						<Typography
							variant='h5'>
							{userData.user_name}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							Mail ID:
						</Typography>
						<Typography
							variant='h5'>
							{userData.mailid}
						</Typography>
					</Stack>

				</Box>
			</Box>
		</Box>
	)
}

export default Profile