import React from 'react'

import './FeedbackItem.css';
import { Avatar, Card, CardHeader, CardMedia } from '@mui/material';

export const FeedbackItem = ({ feedback }) => {
	return (
		<div
			className='feedback-item'>

			<Card
				className='feedback-item-card'>
				<CardHeader
					avatar={
						<Avatar
							src={feedback.avatar}
							alt='Student Name Feedback'
							sx={{
								width: '100px',
								height: '100px',
								margin: '0px 5px'
							}} />
					}
					title={
						<h1
							className='student-name'>
							{feedback.name}
						</h1>
					}
					subheader={
						<span>
							{
								feedback.batches.map((batch) => {
									return (
										batch + ", "
									)
								})
							}
						</span>
					}
				/>

				<CardMedia
					image={feedback.feedback}
					sx={{
						// height: '250px',
						width: '100%',
						aspectRatio: '1024/791'
					}}
				/>

			</Card>

		</div >
	)
}
