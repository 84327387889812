import React from 'react'

import './CourseDetailsContainer.css';

import { Box } from '@mui/material';

import { ContentDetailsContainer } from './Components/ContentDetailsContainer/ContentDetailsContainer';
import { CourseCard } from './Components/CourseCard/CourseCard';

const CourseDetailsContainer = ({ courseData }) => {
	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			width={'100%'}
			className="course-details-wrapper"
			zIndex={1}>

			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'center'}
				alignItems={'stretch'}
				width={'90%'}
				margin={'auto'}
				className="course-details-container"
				gap>

				<Box
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'center'}
					alignItems={'center'}
					width={'66.6666666666%'}
					className='left-row'>

					<ContentDetailsContainer
						courseData={courseData} />
				</Box>

				<Box
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'flex-start'}
					alignItems={'center'}
					width={'33.3333333333%'}
					className='right-row'>

					<CourseCard
						courseData={courseData} />

				</Box>

			</Box>

		</Box >
	)
}

export default CourseDetailsContainer