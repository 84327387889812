import React, { useEffect, useState } from 'react'

import './CourseCardItem.css';
import { Card, CardMedia } from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../Components/Common/firebase';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import { Link } from 'react-router-dom';

export const CourseCardItem = ({ course }) => {

	const [posterImageLink, setPosterImageLink] = useState('');

	useEffect(() => {

		getDownloadURL(ref(storage, 'coursePosters/' + course.posterWideName))
			.then((link) => {
				setPosterImageLink(link);
			})

	}, [course.posterWideName]);

	return (
		<div
			className='course-card-item'>
			<Link
				to={'/course+details/' + course.key}
				style={{
					textDecoration: 'none',
				}}>
				<Card>
					<CardMedia
						sx={{
							width: '300px',
							aspectRatio: '1280/720'
						}}
						image={posterImageLink} />

					<h3>
						{course.name}
					</h3>

					<div
						className='course-card-description'>
						<TrendingUpRoundedIcon fontSize='large' />
						<h2>Beginner to Expert</h2>
					</div>
				</Card>
			</Link>
		</div >
	)
}
