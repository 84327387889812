import React from 'react'

import './StudentFeedback.css';

import fb_01 from "./../../../../Assests/images/fb_01.avif";
import fb_02 from "./../../../../Assests/images/fb_02.avif";
import fb_03 from "./../../../../Assests/images/fb_03.avif";
import fb_04 from "./../../../../Assests/images/fb_04.avif";

import fb_img_01 from "./../../../../Assests/images/fb_img_01.avif";
import fb_img_02 from "./../../../../Assests/images/fb_img_02.avif";
import fb_img_03 from "./../../../../Assests/images/fb_img_03.avif";
import fb_img_04 from "./../../../../Assests/images/fb_img_04.avif";
import { FeedbackItem } from './FeedbackItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const feedbacks = [
	{
		avatar: fb_img_01,
		feedback: fb_01,
		name: 'Janhavi Khisti',
		batches: ['ABC_02', 'ABCPP_01', 'ABDS_01', 'ABP_01', 'CFC_01']
	},
	{
		avatar: fb_img_02,
		feedback: fb_02,
		name: 'Gaurav Nehete',
		batches: ['ABDS_02',]
	},
	{
		avatar: fb_img_03,
		feedback: fb_03,
		name: 'Pramod Pathare',
		batches: ['ABDS_01',]
	},
	{
		avatar: fb_img_04,
		feedback: fb_04,
		name: 'Vivek Dhande',
		batches: ['Batch_0']
	},
];

export const StudentFeedback = () => {
	return (
		<div
			className='student-feedback block'>

			<h1
				className='feedback-title'>
				Student Feedback
			</h1>

			<Swiper
				loop='true'
				freeMode='true'
				slidesPerView={'auto'}
				spaceBetween={40}
				speed='2000'
				modules={[Pagination, Autoplay, Navigation]}
				autoplay={{
					delay: 500,
					disableOnInteraction: false,
				}}
			>
				{
					feedbacks.map((feedback, index) => {
						return (
							<SwiperSlide
								key={index}>
								<FeedbackItem
									feedback={feedback} />
							</SwiperSlide>

						)
					})
				}
				{
					feedbacks.map((feedback, index) => {
						return (
							<SwiperSlide
								key={index}>
								<FeedbackItem
									feedback={feedback} />
							</SwiperSlide>

						)
					})
				}

			</Swiper>
		</div>
	)
}
