import React, { useState } from 'react';

import './Footer.css';

import icon01 from './../../Assests/images/whatsapp_nocolor.webp';
import icon02 from './../../Assests/images/instagram_nocolor.webp';
import icon03 from './../../Assests/images/linkedin_nocolor.webp';
import icon04 from './../../Assests/images/youtube_nocolor.webp';
import icon05 from './../../Assests/images/facebook_nocolor.webp';

import icon06 from './../../Assests/images/whatsapp_color.webp';
import icon07 from './../../Assests/images/instagram_color.webp';
import icon08 from './../../Assests/images/linkedin_color.webp';
import icon09 from './../../Assests/images/youtube_color.webp';
import icon10 from './../../Assests/images/facebook_color.webp';

import Tooltip from '@mui/material/Tooltip';
import { Icon } from '@mui/material';

export const Footer = ({ bg }) => {
	// Code
	const [image01, setImage01] = useState(icon01);
	const [image02, setImage02] = useState(icon02);
	const [image03, setImage03] = useState(icon03);
	const [image04, setImage04] = useState(icon04);
	const [image05, setImage05] = useState(icon05);

	return (
		<div
			className='footer'
			id='footer'
			style={{
				background: bg,
			}}>
			<div className='footerHolder'>

				<div className="map-container">
					{/* Embed Google Maps or use an iframe */}
					<iframe
						title='Map Location'
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d804.5081602096708!2d73.74724073021414!3d19.981750563300274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdded6f806b6fad%3A0x5cc493ef1f92872d!2sSANJYOT&#39;S%20DENTAL%20CARE!5e1!3m2!1sen!2sin!4v1718296656195!5m2!1sen!2sin"
						allowFullScreen={true}
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade">

					</iframe>
				</div>

				<div
					className='footer-address'>
					Address: Shop no. 6, Above Sanjyot Dental Care, Mayuri Tower, ITI Ambad Link Road, Khutwadnagar, Nashik-422007
				</div>


				<div
					className='footer-info-container'>
					<p>
						&copy;2024 Marshalling Void designed and developed by Marshalling Void
					</p>
				</div>

				{/* <div
					className='footer-info-container'>
					<div className='contact'>Contact: +91 8830551355</div>
					<div className='copyright'>Copyright@marshallingvoid2023</div>
					<div className='mail'>marshallingvoid@gmail.com</div>
					<div className='icons'>
						<Tooltip
							title='YouTube'
							placement='top'>
							<div >
								<a href="https://www.youtube.com/@marshallingvoid" target="_blank" rel="noreferrer">
									<img className='youtube'
										src={image04}
										onMouseEnter={() => setImage04(icon09)}
										onMouseOut={() => setImage04(icon04)}
										alt=''
									/>
								</a>
							</div>
						</Tooltip>
						<Tooltip
							title='Instagram'
							placement='top'>
							<div>
								<a href="https://www.instagram.com/marshallingvoid" target="_blank" rel="noreferrer">
									<img className='instagram'
										src={image02}
										onMouseEnter={() => setImage02(icon07)}
										onMouseOut={() => setImage02(icon02)}
										alt=''
									/>
								</a>
							</div>
						</Tooltip>
						<Tooltip
							title='LinkedIn'
							placement='top'>
							<div>
								<a href="https://www.linkedin.com/company/marshallingvoid/viewAsMember=true" target="_blank" rel="noreferrer">
									<img className='linkedin'
										src={image03}
										onMouseEnter={() => setImage03(icon08)}
										onMouseOut={() => setImage03(icon03)}
										alt=''
									/>
								</a>
							</div>
						</Tooltip>
						<Tooltip
							title='Whatsapp'
							placement='top'>
							<div>
								<a href="https://wa.me/+918830551355" target="_blank" rel="noreferrer">
									<img className='whatsapp'
										src={image01}
										onMouseEnter={() => setImage01(icon06)}
										onMouseOut={() => setImage01(icon01)}
										alt=''
									/>
								</a>
							</div>
						</Tooltip>
						<Tooltip
							title='Facebook'
							placement='top'>
							<div>
								<a href="https://www.facebook.com/marshallingvoid" target="_blank" rel="noreferrer">
									<img className='facebook'
										src={image05}
										onMouseEnter={() => setImage05(icon10)}
										onMouseOut={() => setImage05(icon05)}
										alt=''
									/>
								</a>
							</div>
						</Tooltip>
					</div>
				</div> */}
			</div>
		</div>
	)
}
