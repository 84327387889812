import React, { useRef, useEffect } from 'react';
import './HorizontalScroll.css';


var tags = [
	'Asm', 'C', 'C++', 'Java', 'Python', 'Go',
	'Data Structure', 'Algorithms', 'React', 'Node', 'Express', 'Mongo',
	'SQL', 'Git', 'Web Dev', 'OS', 'OpenGL', 'Linux', 'Windows',
	'Win32 SDK', 'COM', 'Qt', 'JavaFX'
];

const HorizontalScroll = () => {
	const scrollRef = useRef(null);
	const scrollSpeed = 2; // pixels per frame
	const frameTime = 16; // approx 60fps

	const handleScroll = () => {
		if (scrollRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
			// Reset scroll position to create circular effect
			if (scrollLeft + clientWidth >= scrollWidth - 1) {
				scrollRef.current.scrollLeft = 1;
			} else if (scrollLeft <= 0) {
				scrollRef.current.scrollLeft = scrollWidth - clientWidth - 1;
			}
		}
	};

	useEffect(() => {
		const autoScroll = () => {
			if (scrollRef.current) {
				scrollRef.current.scrollLeft += scrollSpeed;
				handleScroll();
			}
		};

		const intervalId = setInterval(autoScroll, frameTime);
		return () => clearInterval(intervalId);
	}, []);

	return (
		<div
			ref={scrollRef}
			onScroll={handleScroll}
			className='horizontal-scroll block' 
		>
			{[...tags, ...tags, ...tags].map((item, index) => (
				<div
					key={index}
					className='fow_item'
				>
					{item}
				</div>
			))}
		</div>
	);
};

export default HorizontalScroll;
