import React from 'react'

import './CourseCardContainer.css';
import { CourseCardGroup } from './CourseCardGroup';

export const CourseCardContainer = ({ courses }) => {
	return (
		<div
			className='course_card_container'>

			<h1>
				Classroom Courses
			</h1>

			<h3>
				Hybrid (Offline/Online) Live Lectures
			</h3>

			<CourseCardGroup
				courses={courses} />
		</div>
	)
}
