import React, { useState } from 'react'

import './CourseContent.css';

import { ContentPart } from './Components/ContentParts/ContentPart';

export const CourseContent = ({ courseContent, id }) => {

    const [openPart, setOpenPart] = useState(-1);

    if (!courseContent) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <div
            id={id}
            className='course-content'>

            <div
                className='course-content-container'>

                <h1
                    className='courseContent_Title'>
                    Content
                </h1>

                <div
                    className='content-parts-container'>
                    {
                        courseContent.map((partContent, index) => {
                            return (
                                <ContentPart
                                    isEven={index % 2 === 0}
                                    _key={index}
                                    contentPart={partContent}
                                    isOpen={(openPart === index)}
                                    setOpen={() => {
                                        if (-1 === openPart || index !== openPart) {
                                            setOpenPart(index);
                                        } else {
                                            setOpenPart(-1);
                                        }
                                    }}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
