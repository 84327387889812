import React, { useEffect, useState } from 'react'

import './CourseDetailsBreadcrumbs.css';

import { Stack, Typography, Breadcrumbs } from '@mui/material'

const courseDetailsPageBreadcrumbs = [
	{
		title: 'Home'
	},
	{
		title: 'Courses'
	},
]

function getBreadcrumbComponents({ courseData }) {
	var breadcrumbs = []

	courseDetailsPageBreadcrumbs.forEach(breadcrumb => {
		breadcrumbs.push(
			<Typography
				className='breadcrumb-item'
				color={'#fff'}
				variant='h6'>
				{breadcrumb.title}
			</Typography>
		)
	})

	return (breadcrumbs);
}


const CourseDetailsBreadcrumbs = ({ courseData }) => {

	const [breadcrumbs, setBreadcrumbs] = useState(null);

	useEffect(() => {

		setBreadcrumbs([...getBreadcrumbComponents(courseData),
		<Typography
			className='breadcrumb-item'
			color={'#fff'}
			variant='h6'>
			{courseData.name}
		</Typography>]);

	}, [courseData]);

	return (

		<Stack
			className='breadcrumb-container'
			direction={'row'}
			alignItems={'center'}
			justifyContent={'flex-start'}
			width={'100%'}>

			<Breadcrumbs
				separator={
					<Typography
						className='breadcrumb-item'
						color={'#fff'}
						variant='h6'>
						{'>'}
					</Typography>
				}>
				{breadcrumbs}
			</Breadcrumbs>
		</Stack>)
}

export default CourseDetailsBreadcrumbs