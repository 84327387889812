
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme, width }) => ({
	'& .MuiInputBase-root': {
		color: '#fff !important', // Text color
	},
	'& .MuiInputBase-root .Mui-disabled': {
		color: '#fff !important', // Text color
		'-webkit-text-fill-color': '#aaa !important', // Override webkit text fill color
	},
	'& .MuiInputLabel-root': {
		color: '#fff !important', // Label color
		'&.Mui-focused': {
			color: '#fff !important', // Label color when focused
		},
		'&.Mui-disabled': {
			color: '#aaa !important', // Disabled label color
		},
	},
	// '& .MuiInput-underline:before': {
	// 	borderBottomColor: '#fff !important', // Default underline color
	// },
	// '& .MuiInput-underline:hover:before': {
	// 	borderBottomColor: '#00f !important', // Underline color on hover
	// },
	// '& .MuiInput-underline:after': {
	// 	borderBottomColor: '#fff !important', // Underline color when focused
	// },
	margin: '10px !important',
	boxSizing: 'border-box',
	width: width,
}));

export default CustomTextField;
