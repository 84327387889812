import React from 'react'

import './AboutCourse.css';

export const AboutCourse = ({ courseData, id }) => {
	return (
		<div
			id={id}
			className='about-course'>

			<div
				className='about-course-container'>
				<h2
					className='container-title'>
					About Course
				</h2>
				<div
					className='container-data'>
					{courseData.courseAbout}
				</div>
			</div>
		</div>
	)
}
