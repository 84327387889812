import React from 'react'
import EditProfile from '../EditProfile/EditProfile'
import SocialProfile from '../SocialProfile/SocialProfile'

const ProfileSettingsPseudoRouter = ({ tabValue, userData }) => {

	switch (tabValue) {
		case 1:
			return (
				<EditProfile
					userData={userData} />
			)

		case 2:
			return (
				<SocialProfile
					userData={userData} />
			)

		default:
			return (<></>)
	}

}

export default ProfileSettingsPseudoRouter