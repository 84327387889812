import React, { useEffect, useState } from 'react'
import './MainPage.css';

import { gapi } from 'gapi-script';
import { firestore } from '../../Components/Common/firebase';
import { getDocs, collection } from 'firebase/firestore';

import { LoadingPage } from '../../Components/Common/LoadingPage';
import { Banner } from './Components/BannerComponent/Banner';
import { Reviews } from './Components/ReviewsComponent/Reviews';
import { ClassInfo } from './Components/ClassInfoComponent/ClassInfo';
import { StudentFeedback } from './Components/StudentFeedbackComponent/StudentFeedback';
import { CourseContainer } from './Components/CourseContainerComponent/CourseContainer';
import { WhyChoseMarshallingVoid } from './Components/WhyChooseMVComponent/WhyChoseMarshallingVoid';
import { Box, Stack, Typography } from '@mui/material';

// import ParticleBackground from '../../Components/ParticleBackground/ParticleBackground';

export const MainPage = () => {

	// const [allLectureLinks, setAllLectureLinks] = useState([]);
	const [courseData, setCourseData] = useState([]);

	useEffect(() => {

		async function fetchData() {

			if (0 === courseData.length) {

				await getDocs(collection(firestore, 'Courses'))
					.then((snapShot) => {

						snapShot.forEach((doc) => {
							var courseData = doc.data();


							if (courseData[courseData.currentBatch].tags.includes('live')) {
								setCourseData(data => [
									...data,
									courseData[courseData.currentBatch]
								])
							}

							// setAllLectureLinks(data => [
							// 	...data,
							// 	...courseData[courseData.currentBatch].openLinks
							// ])
						})
					})
			}
		};

		fetchData();

		function start() {
			gapi.client.init({
				clientId: '851777582489-unsfve0umc180bnspc985kdborn51vmc.apps.googleusercontent.com',
				scope: "profile email"
			})
		}

		gapi.load('client:auth2', start);

	}, [courseData.length]);

	if (0 === courseData.length) {
		return (<LoadingPage />)
	}

	return (
		<div className='mainPage'>

			{/* <Banner /> */}

			<Box
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				alignItems={'center'}
				justifyContent={'center'}
				zIndex={'10'}
				position={'relative'}
				gap
				className="mainpage-header-box-container header-padding-block">

				<Box
					display={'flex'}
					flexDirection={'row'}
					width={'100%'}
					height={'100%'}
					alignItems={'center'}
					justifyContent={'center'}
					zIndex={'10'}
					position={'relative'}
					gap>
					<Stack
						width={'60%'}
						direction={'column'}
						zIndex={'1'}
						justifyContent={'center'}
						alignItems={'center'}
						gap>
						<Typography
							variant='h6'
							color={'#fff'}>
							STEPS TO SUCCESS
						</Typography>

						<Typography
							variant='h2'
							color={"#00ffff"}
							textAlign={'center'}>
							ABCD - Always Be Code Digger
						</Typography>

						<Typography
							variant='h5'
							color={"#fff"}
							textAlign={'center'}
							lineHeight={'0.8'}>
							<p>Master <strong>C, C++, Java, Python, and MERN Stack!</strong></p>
							<p>Ace <strong>DSA</strong> with hands-on projects. </p>
							<strong>Kickstart your successful career today!</strong>
						</Typography>

					</Stack>
				</Box>

				<CourseContainer
					coursesData={courseData} />

			</Box>

			<ClassInfo />

			<WhyChoseMarshallingVoid />

			<div
				className='feedback-review-wrapper'>
				<div
					className='feedback-review-container'>

					<StudentFeedback />

					<Reviews />
				</div>
			</div>
		</div >
	)
}
