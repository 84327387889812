import React from 'react'

import './CourseContainer.css';
import { CardContainer } from './CardContainer';
import { Box } from '@mui/material';

export const CourseContainer = ({ coursesData }) => {

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			width={'100%'}
			alignItems={'center'}
			justifyContent={'center'}>
				
			<h1
				className='course-container-title'>
				Courses
			</h1>

			<CardContainer
				coursesData={coursesData} />
		</Box>
	)
}
