import React from 'react'

import './CourseCardGroup.css';
import { CourseCardItem } from './CourseCardItem';

export const CourseCardGroup = ({courses}) => {
	return (
		<div
			className='course_card_group'>
			{
				courses.map((course) => {
					return(
						<CourseCardItem
							course={course}/>
					)
				})
			}
		</div>
	)
}
