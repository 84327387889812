
import React from 'react'

import './SettingsTabs.css';

import { Tab, Tabs } from '@mui/material'
import { FaEdit } from "react-icons/fa";
import { VscAccount } from "react-icons/vsc";

const settingsTabItems = [
	{
		title: "Edit Profile",
		value: 1,
		icon: <FaEdit />
	},
	{
		title: "Social Profile",
		value: 2,
		icon: <VscAccount />
	},
]

const SettingsTabs = ({ tabValue, setTabValue }) => {
	return (
		<Tabs
			className='edit-profile-tabs'
			value={tabValue}
			onChange={(e, newValue) => {
				setTabValue(newValue);
			}}
			indicatorColor='secondary'
			textColor='#fff !important'
			sx={{
				zIndex: 2,
			}}>
			{
				settingsTabItems.map((tabItem, index) => {
					return (
						<Tab
							key={index}
							icon={tabItem.icon}
							value={tabItem.value}
							label={tabItem.title}
							iconPosition='start'
						/>
					)
				})
			}
		</Tabs>
	)
}

export default SettingsTabs