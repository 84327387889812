import React, { useEffect, useState } from 'react'

import './RegistrationFormPage.css';

import { Box, Button, Dialog, DialogContent, DialogTitle, FilledInput, FormControl, InputAdornment, InputLabel, Typography } from '@mui/material';
import styled from 'styled-components';

import SendRoundedIcon from '@mui/icons-material/SendRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SportsScoreRoundedIcon from '@mui/icons-material/SportsScoreRounded';
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import ConfettiExplosion from 'react-confetti-explosion';


const CustomFormControl = styled(FormControl)(({ theme }) => ({
	'&': {
		width: '60% !important',
		gap: '5px'
	},
	'& .MuiInputLabel-root': {
		color: '#fff', // Custom color for label
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: '#fff', // Custom color for label
	},
	'& .MuiInputBase-root': {
		backgroundColor: '#435c67', // Custom background color
		'&.MuiFilledInput-root': {
			color: '#fff !important',
		},
		'&.MuiFilledInput-root:before': {
			color: '#fff',
			borderBottom: '1px solid #fff !important',
		},
		'&.MuiFilledInput-root.Mui-focused:before': {
			color: '#fff', // Custom color for text
			borderBottom: '2px solid #fff !important', // Custom color for bottom border
		},
		'&.MuiFilledInput-root.Mui-focused:after': {
			color: '#fff', // Custom color for text
			borderBottom: '2px solid #fff !important', // Custom color for bottom border
		},
		'& .MuiFilledInput-underline:before': {
			borderBottom: '2px solid #fff !important', // Custom color for bottom border
		},
		'& .MuiFilledInput-underline:hover:before': {
			borderBottom: '2px solid #fff !important', // Custom hover color for bottom border
		},
		'& .MuiFilledInput-underline:after': {
			borderBottom: '2px solid #fff !important', // Custom focus color for bottom border
		},
	},
	'& .MuiIconButton-root': {
		color: '#fff', // Custom color for icon button
	},
	'& .MuiSvgIcon-root': {
		color: '#fff', // Custom color for SVG icon
	},
}));

const CustomButton = styled(Button)(({ theme }) => ({
	backgroundColor: '#4caf50 !important', // Enabled background color (green)
	color: '#ffffff', // Text color
	'&:hover': {
		backgroundColor: '#66bb6a', // Hover background color (lighter green)
	},
	'& .MuiButton-endIcon': {
		color: '#ffffff', // Icon color
	},
	padding: '10px 20px', // Custom padding
	fontSize: '1.4rem !important', // Custom font size

	'&.Mui-disabled': {
		backgroundColor: '#81c784 !important', // Disabled background color (light green)
		color: '#ffffff', // Disabled text color
		'& .MuiButton-endIcon': {
			color: '#ffffff', // Disabled icon color
		},
	}
}));

function getDoubleDigits(value) {
	if (10 > value)
		return ('0' + value)

	return (value);
}

function getHour(value) {
	return (
		value > 12
			?
			value % 12
			:
			value
	)
}

function getTimeZone(value) {
	return (
		value > 12
			?
			'PM'
			:
			'AM'
	)
}

function getTime(date) {
	return (getDoubleDigits(getHour(date.getHours())) + ':' + getDoubleDigits(date.getMinutes()) + ' ' + getTimeZone(date.getHours()));
}

export const RegistrationFormPage = () => {

	const [fullName, setFullName] = useState('');
	const [mailId, setMailID] = useState('');
	const [contactNo, setContactNo] = useState('');

	const [isMailIdVaid, setMailIDValid] = useState();
	const [isContactNoValid, setContactNoValid] = useState();

	const [daysDifference, setDaysDifference] = useState(0);
	const [hoursDifference, setHoursDifference] = useState(0);
	const [minutesDifference, setMinutesDifference] = useState(0);
	const [secondsDifference, setSecondsDifference] = useState(0);

	const [isExploding, setIsExploding] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const timer = setInterval(() => {

			const diff = Math.abs(new Date("2024-07-31T20:00:00") - new Date());

			const msInSecond = 1000;
			const msInMinute = msInSecond * 60;
			const msInHour = msInMinute * 60;
			const msInDay = msInHour * 24;

			const days = Math.floor(diff / msInDay);
			const hours = Math.floor((diff % msInDay) / msInHour);
			const minutes = Math.floor((diff % msInHour) / msInMinute);
			const seconds = Math.floor((diff % msInMinute) / msInSecond);

			setDaysDifference(days);
			setHoursDifference(hours);
			setMinutesDifference(minutes);
			setSecondsDifference(seconds);

		}, 1000);

		return () => clearInterval(timer);
	}, []);

	const handleRegistration = async (e) => {

		e.preventDefault();

		const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSdyPMT4YFeMqC6mObE87Gdb_ybm_osDiH0T3q-zWTVxTJpdqg/formResponse';
		const formFields = {
			'entry.1568182928': fullName,  // Replace with your actual field entry IDs
			'entry.1269665321': mailId,
			'entry.1512918079': contactNo,
		};
		const formBody = new URLSearchParams(formFields).toString();

		await fetch(googleFormURL, {
			method: 'POST',
			mode: 'no-cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: formBody,
		});

		setIsExploding(true);
		setOpen(true);

		setTimeout(() => {
			setIsExploding(false);
		}, 2000);

		// const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSdyPMT4YFeMqC6mObE87Gdb_ybm_osDiH0T3q-zWTVxTJpdqg/formResponse';
		// // const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSfe54hBRNlKZcAnrc4QKlIfn3-vVbzbF9nZe0YTRm0rWGS81g/viewform';
		// const formFields = {
		// 	'entry.2098323026': fullName,  // Replace with your actual field entry IDs
		// 	'entry.342397464': mailId,
		// 	'entry.2077125496': contactNo,
		// };
		// const formBody = new URLSearchParams(formFields).toString();

		// await fetch(googleFormURL, {
		// 	method: 'POST',
		// 	mode: 'no-cors',
		// 	headers: {
		// 		'Content-Type': 'application/x-www-form-urlencoded',
		// 	},
		// 	body: formBody,
		// });


		// const response = await fetch('https://script.google.com/macros/s/AKfycbylxoII4dONPSJOy7xtlYXmUBYv7IgLqcvzGJKiusV0IdThqdHufYw1SB7HZrI5XvNHwA/exec', {
		// 	method: 'POST',
		// 	body: JSON.stringify({ fullName, mailId, contactNo }),
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// 	mode: 'no-cors',
		// });

		// if (response.ok) {
		// 	alert('Form submitted successfully');


		setIsExploding(true);
		setOpen(true);

		setTimeout(() => {
			setIsExploding(false);
		}, 2000);

		// } else {
		// 	alert('Failed to submit form');
		// }
	}

	const handleClose = () => {
		setOpen(false);
	}

	return (
		<div
			className='registration-form-page'>
			<div
				className='registration-form-container'>
				<Box
					className='registration-form'>
					<Box
						className='registration-form-header'
						display={'flex'}
						flexDirection={'column'}
						gap={2}>
						<Typography
							variant='h3'>
							Java & Python: Free Session Registration
						</Typography>
						<Typography
							variant='h5'>
							Learn how to crack your dream job/company with a simple framework. We will cover the contents and outcome of course.
						</Typography>
					</Box>
					<Box
						className='registration-form-body'
						display={'flex'}
						flexDirection={'column'}>
						<CustomFormControl variant="filled">
							<InputLabel htmlFor="filled-adornment-password">
								Full Name
								<span
									style={{
										color: '#FF3F3F',
										fontSize: '1.4rem',
										fontWeight: '700'
									}}>
									*
								</span>
							</InputLabel>
							<FilledInput
								id="full-name"
								value={fullName}
								onChange={(event) => {
									setFullName(event.target.value);
								}}
								endAdornment={
									<InputAdornment position="end">
										<PersonRoundedIcon
											sx={{
												fontSize: '2rem'
											}} />
									</InputAdornment>
								}
							/>
						</CustomFormControl>
						<CustomFormControl variant="filled">
							<InputLabel htmlFor="filled-adornment-password">
								Mail ID
								<span
									style={{
										color: '#FF3F3F',
										fontSize: '1.4rem',
										fontWeight: '700'
									}}>
									*
								</span>
							</InputLabel>
							<FilledInput
								id="mailid"
								value={mailId}
								onChange={(event) => {

									setMailID(event.target.value)

									const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
									if (emailRegex.test(event.target.value)) {
										setMailIDValid(true)
									} else {
										setMailIDValid(false)
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<MarkEmailReadRoundedIcon
											sx={{
												fontSize: '2rem'
											}} />
									</InputAdornment>
								}
							/>
							<span
								style={{
									color: '#ff4444'
								}}>
								{
									!isMailIdVaid
										?
										'InValid'
										:
										''
								}
							</span>
						</CustomFormControl>
						<CustomFormControl variant="filled">
							<InputLabel htmlFor="filled-adornment-password">
								Phone No
								<span
									style={{
										color: '#FF3F3F',
										fontSize: '1.4rem',
										fontWeight: '700'
									}}>
									*
								</span>
							</InputLabel>
							<FilledInput
								id="contant no"
								value={contactNo}
								onChange={(event) => {

									setContactNo(event.target.value)

									const regex = /^[0-9]+$/;
									if (regex.test(event.target.value) && (10 === event.target.value.length)) {
										setContactNoValid(true)
									} else {
										setContactNoValid(false)
									}
								}}
								startAdornment={
									<InputAdornment position="start">
										<span
											style={{
												color: '#fff'
											}}>+91</span>
									</InputAdornment>
								}
								endAdornment={
									<InputAdornment position="end">
										<PhoneAndroidRoundedIcon
											sx={{
												fontSize: '2rem'
											}} />
									</InputAdornment>
								}
							/>
							<span
								style={{
									color: '#ff4444'
								}}>
								{
									!isContactNoValid
										?
										'InValid'
										:
										''
								}
							</span>
						</CustomFormControl>

						<CustomButton
							disabled={!(isMailIdVaid && isContactNoValid)}
							onClick={(e) => {
								handleRegistration(e);
							}}
							variant='contained'
							endIcon={<SendRoundedIcon />}>
							Register
						</CustomButton>
						<Typography
							variant='subtitle2'>
							By registering, you consent to the sharing of your details with the Marshalling Void team. Your information will be used to communicate with you about this event and provide updates on future events						</Typography>
					</Box>
				</Box>

				<Box
					className='registration-form-container-footer'
					display={'flex'}
					justifyContent={'space-evenly'}
					sx={{
						width: '100%',
						position: 'fixed',
						bottom: '10px'
					}}>
					<Box
						display={'flex'}
						flexDirection={'column'}
						justifyContent={'space-evenly'}
						alignItems={'center'}>
						<Typography
							variant='h4'>
							{new Date("2024-07-31T20:00:00").toDateString()}, {getTime(new Date("2024-07-31T20:00:00"))}
						</Typography>
						<Typography
							variant='subtitle1'>
							Happening On
						</Typography>
					</Box>

					<Box
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'space-evenly'}
						alignItems={'flex-start'}
						gap={'10px'}>
						<Box
							display={'flex'}
							flexDirection={'column'}
							justifyContent={'space-evenly'}
							alignItems={'center'}>
							<Typography
								variant='h4'>
								{getDoubleDigits(daysDifference)}
							</Typography>
							<Typography
								variant='subtitle1'>
								Days
							</Typography>
						</Box>
						<Typography
							variant='h4'>
							:
						</Typography>
						<Box
							display={'flex'}
							flexDirection={'column'}
							justifyContent={'space-evenly'}
							alignItems={'center'}>
							<Typography
								variant='h4'>
								{getDoubleDigits(hoursDifference)}
							</Typography>
							<Typography
								variant='subtitle1'>
								Hours
							</Typography>
						</Box>
						<Typography
							variant='h4'>
							:
						</Typography>
						<Box
							display={'flex'}
							flexDirection={'column'}
							justifyContent={'space-evenly'}
							alignItems={'center'}>
							<Typography
								variant='h4'>
								{getDoubleDigits(minutesDifference)}
							</Typography>
							<Typography
								variant='subtitle1'>
								Minutes
							</Typography>
						</Box>
						<Typography
							variant='h4'>
							:
						</Typography>
						<Box
							display={'flex'}
							flexDirection={'column'}
							justifyContent={'space-evenly'}
							alignItems={'center'}>
							<Typography
								variant='h4'>
								{getDoubleDigits(secondsDifference)}
							</Typography>
							<Typography
								variant='subtitle1'>
								Seconds
							</Typography>
						</Box>
					</Box>
				</Box>
			</div>

			<Dialog
				className='registration-success-dialogbox'
				open={open}
				onClose={() => {
					handleClose()
				}}>
				<DialogTitle>
					<Typography
						variant='h3'>
						Registration Successful
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography
						variant='h4'>
						You Are One Step Closer To Your Dreams
					</Typography>
					<Box
						display={'flex'}
						justifyContent={'space-between'}>
						<Box
							display={'flex'}
							justifyContent={'space-between'}>
							<FlagRoundedIcon
								className='dialog-content-icon'

							/>
							<DirectionsRunRoundedIcon
								className='dialog-content-icon'

							/>
						</Box>
						<SportsScoreRoundedIcon
							className='dialog-content-icon'

						/>
					</Box>
				</DialogContent>
				{
					isExploding
					&&
					<ConfettiExplosion
						zIndex={'10'}
						force={1}
						duration={5000}
						particleCount={300}
						width={2600}
					/>
				}
			</Dialog>
		</div >
	)
}
