import React from 'react'

import './ContentPart.css';

import { Collapse, IconButton } from '@mui/material';

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Item } from '../Item/Item';


export const ContentPart = ({ _key, contentPart, isOpen, setOpen, isEven }) => {
    // Code

    return (
        <div
            key={_key}
            className='contentPart'
            style={{
                'backdropFilter': isEven ? 'blur(1px)' : '',
                '-webkit-backdrop-filter': isEven ? 'blur(1px)' : ''
            }}
        >

            <div
                className='contentPart_TitleGroup'
                onClick={() =>
                    setOpen()
                }>

                <div
                    className='contentPart_Title'>
                    {contentPart.name}
                </div>

                <IconButton
                    aria-label="expand"
                    className='contentPart_Expand'
                    style={{
                        color: '#fff',
                        backgroundColor: 'rgba(0, 0, 0, 0.0)',
                        padding: 'none !important',
                        transform: (isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')
                    }}>
                    <ExpandMoreRoundedIcon
                        style={
                            {
                                width: '2rem',
                                height: '2rem'
                            }
                        }
                    />
                </IconButton>
            </div>

            <Collapse
                className='contentPart_ItemGroup'
                in={isOpen}
                timeout={500}>
                {
                    contentPart.children.map((item, index) => {
                        return (
                            <Item
                                key={index}
                                itemData={item}
                            />)
                    })
                }
            </Collapse>
        </div >
    )
}
