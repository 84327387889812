import React, { useCallback, useEffect, useState } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

function getNrPoints(width) {
	if (width <= 350)
		return (20);
	else if (width <= 400)
		return (80);
	else if (width <= 500)
		return (100);
	else if (width <= 800)
		return (150);
	else if (width <= 1100)
		return (180);
	else if (width <= 1600)
		return (200);
	else
		return (250);

}

const ParticleBackground = ({ _speed = 1, repulse_distance = 90 }) => {
	const particlesInit = useCallback(async engine => {
		await loadSlim(engine);
	}, []);

	const particlesLoaded = useCallback(async container => {
	}, []);

	const [nrPoints, setNrPoints] = useState(getNrPoints(window.innerWidth));

	useEffect(() => {
		const handleResize = () => {
			setNrPoints(getNrPoints(window.innerWidth));
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Particles
			id="tsparticles"
			init={particlesInit}
			loaded={particlesLoaded}
			options={{
				background: {
					color: {
						value: "#000"
					},
					image: "",
					position: "",
					repeat: "",
					size: "",
					opacity: 1
				},
				backgroundMask: {
					composite: "destination-out",
					cover: {
						color: {
							value: "#fff"
						},
						opacity: 1
					},
					enable: false
				},
				fpsLimit: 120,
				interactivity: {
					events: {
						onClick: {
							enable: true,
							mode: "push",
						},
						onHover: {
							enable: true,
							mode: "repulse",
						},
						resize: true,
					},
					modes: {
						push: {
							quantity: 0,
						},
						repulse: {
							distance: repulse_distance,
							duration: 0.4,
						},
					},
				},
				particles: {
					color: {
						value: "#ffffff",
					},
					links: {
						color: "#aaaaaa",
						distance: 150,
						enable: true,
						opacity: 0.4,
						width: 1,
					},
					move: {
						direction: "none",
						enable: true,
						outModes: {
							default: "bounce",
						},
						random: false,
						speed: _speed,
						straight: false,
					},
					number: {
						density: {
							enable: false,
							area: 800,
						},
						value: nrPoints,
					},
					opacity: {
						value: 0.3,
					},
					shape: {
						type: "circle",
					},
					size: {
						value: { min: 1, max: 5 },
					},
				},
				detectRetina: true,
			}}
		/>
	);
};

export default ParticleBackground;