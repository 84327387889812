import React, { Fragment } from 'react'

import './SideBar.css';

import { Box, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from '@mui/material'

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import { useNavigate } from 'react-router-dom';

const menuItems = [
	{
		title: "Dashboard",
		icon: <DashboardRoundedIcon
			sx={{
				fontSize: '1.4rem !important',
				color: '#fff'
			}} />,
		to: '/user/dashboard',
	},
	{
		title: "Profile",
		icon: <PersonRoundedIcon
			sx={{
				fontSize: '1.4rem !important',
				color: '#fff'
			}} />,
		to: '/user/profile',
	},
	{
		title: "My Courses",
		icon: <MenuBookRoundedIcon
			sx={{
				fontSize: '1.4rem !important',
				color: '#fff'
			}} />,
		to: '/user/enrolled-courses/all-courses',
	}
]

const accountItems = [
	// {
	// 	title: "Logout",
	// 	icon: <LogoutRoundedIcon
	// 		sx={{
	// 			fontSize: '1.4rem !important',
	// 			color: '#fff'
	// 		}} />,
	// },
	{
		title: "Settings",
		icon: <SettingsRoundedIcon
			sx={{
				fontSize: '1.4rem !important',
				color: '#fff'
			}} />,
		to:"/user/settings"
	}
]

const SideBar = () => {

	const navigate = useNavigate();

	const onClickHandle = (to) => {
		navigate(to);
	}

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			className='sidebar-container'
			borderRadius={'10px'}
			width='100%'
			sx={{
				backgroundColor: '#222 !important'
			}}>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='space-evenly'
				borderRadius={'10px'}
				width='90%'
				sx={{
					backgroundColor: '#222 !important'
				}}>

				<Typography
					padding={'3px 0px !important'}
					variant='h5'>
					Menu
				</Typography>

				<MenuList
					sx={{
						width: '100%',
						padding: '3px 0px !important'
					}}
					dense>

					{
						menuItems.map((menuItem, index) => {
							return (
								<Fragment
									key={index}>
									<MenuItem
										sx={{
											borderRadius: '10px !important',
											padding: '2px !important'
										}}
										onClick={() => {
											onClickHandle(menuItem.to)
										}}>
										<ListItemIcon>
											{menuItem.icon}
										</ListItemIcon>
										<ListItemText>
											<Typography
												variant='subtitle1'>
												{menuItem.title}
											</Typography>
										</ListItemText>
									</MenuItem>

								</Fragment>
							)
						})
					}

				</MenuList>

			</Box>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='space-evenly'
				className='sidebar-container'
				borderRadius={'10px'}
				width='90%'
				sx={{
					backgroundColor: '#222 !important'
				}}>

				<Typography
					padding={'3px 0px !important'}
					variant='h5'>
					Accounts
				</Typography>

				<MenuList
					sx={{
						width: '100%',
						padding: '3px 0px !important'
					}}
					dense>

					{
						accountItems.map((accountItem, index) => {
							return (
								<Fragment
									key={index}>
									<MenuItem
										sx={{
											borderRadius: '10px !important',
											padding: '2px !important'
										}}
										onClick={() => {
											onClickHandle(accountItem.to)
										}}>
										<ListItemIcon>
											{accountItem.icon}
										</ListItemIcon>
										<ListItemText>
											<Typography
												variant='subtitle1'>
												{accountItem.title}
											</Typography>
										</ListItemText>
									</MenuItem>

								</Fragment>
							)
						})
					}

				</MenuList>

			</Box>

		</Box>
	)
}

export default SideBar