import React from 'react'

import './Item.css';

export const Item = ({ itemData, style, key }) => {
    return (
        <div
            key={key}
            style={style}
            className='item'>
            {itemData}
        </div>
    )
}
