import React, { useEffect, useState } from 'react'

import './User.css';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Components/Common/firebase';
import { UserProfileCard } from './Components/UserProfileCard/UserProfileCard';

import UserBreadcrumbs from './Components/UserBreadcrumbs/UserBreadcrumbs';
import SideBar from './Components/SideBar/SideBar';

const User = () => {

	const [user] = useState(JSON.parse(localStorage.getItem('user')));
	const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userdata')));

	useEffect(() => {

		if (null == localStorage.getItem('userdata')) {
			getDoc(doc(firestore, 'Users/' + user.id))
				.then((docSnapshot) => {
					var userData = docSnapshot.data();
					localStorage.setItem('userdata', JSON.stringify(userData));
					setUserData(docSnapshot.data());
				})
		}

	}, [user])

	return (
		<div
			className='user-wrapper header-padding-block'>

			<Box
				display={'flex'}
				flexDirection={'row'}
				width={'100%'}
				justifyContent={'center'}
				alignItems={'center'}
				gap>
				<UserBreadcrumbs />
			</Box>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='flex-start'
				width='100%'
				minHeight={'100vh'}
				className='page-content'
				padding={'20px 0px '}
				zIndex={2}>

				<Box
					display='flex'
					flexDirection='row'
					alignItems='flex-start'
					alignContent='flex-start'
					justifyContent='space-evenly'
					className='page-content-container'
					width='80%'
					minHeight={'100vh'}
					margin={'auto'}
					zIndex={2}
					gap>

					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='space-evenly'
						width='20%'
						className='container-left-column'
						position={'sticky'}
						top={"100px"}
						zIndex={2}
						gap={'10px'}>

						<UserProfileCard
							user={userData} />

						<SideBar />

					</Box>

					<Outlet />

				</Box>

			</Box>
		</div>
	)
}

export default User