import React, { useEffect, useState } from 'react'

import './CoursePage.css'

import { firestore } from '../../Components/Common/firebase';
import { getDocs, collection } from 'firebase/firestore';

import { CourseCardContainer } from './CourseCardContainer';
import { Box, Typography } from '@mui/material';

export const CoursePage = () => {

	const [courseData, setCourseData] = useState([]);

	useEffect(() => {

		async function fetchData() {
			if (0 === courseData.length) {

				await getDocs(collection(firestore, 'Courses'))
					.then((snapShot) => {

						snapShot.forEach((doc) => {
							var courseData = doc.data();

							if (courseData[courseData.currentBatch].tags.includes('live')) {
								setCourseData(data => [
									...data,
									courseData[courseData.currentBatch]
								])
							}
						})
					})
			}
		};

		fetchData();
	}, [courseData.length]);

	return (
		<div className='coursePage header-padding-block'>

			<Box
				display={'flex'}
				width={'98%'}
				height={'100px !important'}
				flexDirection={'row'}
				justifyContent={'center'}
				alignItems={'center'}
				className={'course-page-header '}
			>
				<Typography
					variant='h2'
					sx={{
						color: '#fff !important'
					}}>
					Courses
				</Typography>
			</Box>

			<CourseCardContainer
				courses={courseData} />

		</div>
	)
}
