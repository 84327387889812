import React from 'react'

import './CourseBatch.css';

import { Box, Stack, Typography } from '@mui/material';
import LecturesContainer from './Components/LectureContainer/LecturesContainer';

// import UserDrawer from '../DrawerComponents/UserDrawer';

const CourseBatch = ({ user, batch }) => {

	return (
		<Box
			className='courses-batch-wrapper'>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='space-evenly'
				width='99%'
				height='100%'
				className='courses-batch-container header-padding-block'
				borderRadius={'20px'}
				zIndex={2}>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					justifyContent='space-evenly'
					width='100%'
					height='100%'
					padding={'2px 0px'}
					className='courses-batch-header-container'
					borderTop='2px solid #777'
					borderBottom='2px solid #777'
				>
					<Stack>
						<Typography
							sx={{
								color: '#fff',
								filter: 'drop-shadow(2px 2px 2px #000)',
								textAlign: 'center'
							}}
							variant='h4'>
							{batch.name}
						</Typography>

						<Typography
							sx={{
								color: '#fff',
								filter: 'drop-shadow(2px 2px 2px #000)',
								textAlign: 'center'
							}}
							variant='h6'>
							{batch.batch}
						</Typography>

					</Stack>

				</Box>

				<LecturesContainer
					batch={batch} />

			</Box>
		</Box>
	)
}

export default CourseBatch