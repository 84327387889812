import React from 'react'

import './ClassInfo.css';

export const ClassInfo = () => {
	return (
		<div
			className='class-info block'>
			<div
				className='class-info-item'>
				<div
					className='class-info-item-content'>
					4 Years
				</div>
			</div>
			<div
				className='class-info-item'>
				<div
					className='class-info-item-content'>
					5 Courses
				</div>
			</div>
			<div
				className='class-info-item'>
				<div
					className='class-info-item-content'>
					12+ Batches
				</div>
			</div>
			<div
				className='class-info-item'>
				<div
					className='class-info-item-content'>
					5+ Projects
				</div>
				<div
					className='class-info-item-sub-content'>
					Per Course
				</div>
			</div>
			<div
				className='class-info-item'>
				<div
					className='class-info-item-content'>
					500+ Codes
				</div>
			</div>
		</div>
	)
}
