import React, { useEffect, useState } from 'react'

import './Dashboard.css';

import { Box, Card, CardContent, CardHeader, Icon, Stack, Typography } from '@mui/material'
import { firestore } from '../../../../Components/Common/firebase'
import { doc, getDoc } from 'firebase/firestore'

import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import TvRoundedIcon from '@mui/icons-material/TvRounded';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';

function getEnrolledCourseCount(userData) {
	var count = 0;

	if (null !== userData.classroom_offline_courses_enrolled)
		count += userData.classroom_offline_courses_enrolled.length

	if (null !== userData.classroom_online_courses_enrolled)
		count += userData.classroom_online_courses_enrolled.length

	if (null !== userData.ondemand_courses_enrolled)
		count += userData.ondemand_courses_enrolled.length

	return (count);
}

function getOfflineCoursesCount(userData) {
	var count = 0;

	if (null !== userData.classroom_offline_courses_enrolled)
		count += userData.classroom_offline_courses_enrolled.length

	return (count);
}

function getOnlineCoursesCount(userData) {
	var count = 0;

	if (null !== userData.classroom_online_courses_enrolled)
		count += userData.classroom_online_courses_enrolled.length

	return (count);
}

function getOndemandCoursesCount(userData) {
	var count = 0;

	if (null !== userData.ondemand_courses_enrolled)
		count += userData.ondemand_courses_enrolled.length

	return (count);
}

const Dashboard = ({ user }) => {

	const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userdata')))

	useEffect(() => {

		if (null === userData && null !== user) {
			getDoc(doc(firestore, 'Users/' + user.id))
				.then((docSnapshot) => {
					setUserData(docSnapshot.data());
					var userData = docSnapshot.data();
					localStorage.setItem('userdata', JSON.stringify(userData));
				})
		}


	}, [localStorage.getItem('userdata'), user, null == userData]);

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			className='dashboard-wrapper'>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='space-evenly'
				width='100%'
				className='dashboard-container'>

				<Box
					display='flex'
					flexDirection='row'
					alignItems='center'
					justifyContent='center'
					flexWrap={'wrap'}
					width='100%'
					gap={
						'20px'
					}
					className='dashboard-list'>

					<Card
						raised
						sx={{
							width: 'max-content !important',
							padding: "10px !important"
						}}
						className='dashboard-list-item'>
						<CardHeader
							title={
								<Typography
									color={'#aaa !important'}
									variant='h5'>
									Enrolled Courses
								</Typography>
							}
						/>
						<CardContent
							sx={{
								padding: '0px 10px !important',
							}}>
							<Typography
								color={'#fff !important'}
								variant='h3'>
								{getEnrolledCourseCount(userData)}
							</Typography>
						</CardContent>
					</Card>

					<Card
						raised
						sx={{
							width: 'max-content !important',
							padding: "10px !important"
						}}
						className='dashboard-list-item'>
						<CardHeader
							title={
								<Typography
									color={'#aaa !important'}
									variant='h5'>
									Offline Courses
								</Typography>
							}
						/>
						<CardContent
							sx={{
								padding: '0px 10px !important',
							}}>
							<Typography
								color={'#fff !important'}
								variant='h3'>
								{getOfflineCoursesCount(userData)}
							</Typography>
						</CardContent>
					</Card>

					<Card
						raised
						sx={{
							width: 'max-content !important',
							padding: "10px !important"
						}}
						className='dashboard-list-item'>
						<CardHeader
							title={
								<Typography
									color={'#aaa !important'}
									variant='h5'>
									Offline Courses
								</Typography>
							}
						/>
						<CardContent
							sx={{
								padding: '0px 10px !important',
							}}>
							<Typography
								color={'#fff !important'}
								variant='h3'>
								{getOnlineCoursesCount(userData)}
							</Typography>
						</CardContent>
					</Card>

					<Card
						raised
						sx={{
							width: 'max-content !important',
							padding: "10px !important"
						}}
						className='dashboard-list-item'>
						<CardHeader
							title={
								<Typography
									color={'#aaa !important'}
									variant='h5'>
									Online Courses
								</Typography>
							}
						/>
						<CardContent
							sx={{
								padding: '0px 10px !important',
							}}>
							<Typography
								color={'#fff !important'}
								variant='h3'>
								{getOndemandCoursesCount(userData)}
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Box>
		</Box>
	)
}

export default Dashboard