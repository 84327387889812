import React from 'react'

import './LectureItem.css';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import CountdownTimer from '../../../../../../Components/CountdownTimer/CountdownTimer';

import { getTime } from '../../../../../../Function/actions';
import LectureDataButtons from '../LectureDataButtons/LectureDataButtons';

function getDoubleDigit(value) {
	if (10 > value)
		return ('0' + value)
	else
		return (value);
}

const LectureItem = ({ lectureNo, lecture, toShowTimer, setLectureData, isRecorded }) => {

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-between'
			width='100%'
			height='100%'
			className='lectures-list-item'
			borderRadius={'10px'}
			gap
			onClick={() => {
				setLectureData(lecture)
			}}
			sx={{
				cursor: 'pointer',
				border: '1px solid #555',
				padding: '10px',
				boxSizing: 'border-box',
			}}>

			<Stack
				width={'100%'}
				direction={'column'}
				gap>

				<Stack
					width={'100%'}
					direction={isRecorded ? 'row' : 'column'}
					justifyContent={'space-between'}
					alignItems={'center'}
					className='lecture-date-buttons-group'
					gap>

					{
						lecture.title
							?
							<Stack
								width={'100%'}
								direction={'row'}
								// justifyContent={'space-between'}
								alignItems={'center'}
								gap>
								<Typography
									variant='subtitle1'>
									{
										getDoubleDigit(lectureNo)
									}
								</Typography>

								<Typography
									variant='subtitle1'>
									{
										lecture.title
									}
								</Typography>
							</Stack>
							:
							<Stack
								width={'100%'}
								direction={'row'}
								justifyContent={'space-between'}
								alignItems={'center'}>


								<Typography
									color={'#ffff00'}
									sx={{
										filter: 'drop-shadow(1px 1px 1px #000)'
									}}
									variant='subtitle1'>
									{new Date(lecture.lectureDateTime).toDateString()}
								</Typography>

								<Typography
									variant='subtitle1'>
									{getTime(lecture.lectureDateTime)}
								</Typography>

								{
									lecture.lectureIsHoliday
										?
										<Typography
											color={'#ffa500'}
											variant='h6'>
											Holiday
										</Typography>
										:
										<Stack
											direction={'row'}
											gap>
											<Typography
												variant='subtitle1'>
												Lecture No:
											</Typography>
											<Typography
												color={'#ffff00'}
												sx={{
													filter: 'drop-shadow(1px 1px 1px #000)'
												}}>
												{getDoubleDigit(lectureNo)}
											</Typography>
										</Stack>
								}
							</Stack>
					}

					<LectureDataButtons
						lecture={lecture} />

				</Stack >

				{
					toShowTimer
					&&
					(new Date(lecture.lectureAvailableTillDateTime) - new Date()) > 0
					&&
					<CountdownTimer
						toDateTime={new Date(lecture.lectureAvailableTillDateTime)} />
				}
			</Stack >

			{
				!isRecorded
				&&
				<Typography
					color={'#00ff0077'}
					variant='subtitle2'>
					click to view your attendance
				</Typography>
			}

		</Box >
	)
}

export default LectureItem