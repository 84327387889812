import React, { useState } from 'react'

import { Box, Typography } from '@mui/material'
import { getTime } from '../../../../../../Function/actions'
import LectureDataButtons from '../LectureDataButtons/LectureDataButtons'

import './LectureItem.css';
import LectureAttendanceContainer from '../LectureAttendanceContainer/LectureAttendanceContainer';

const LectureItem = ({ lectureData }) => {

	if (!lectureData.lectureDateTime) {
		return (<></>)
	}


	return (
		<Box
			display={'flex'}
			width={'100%'}
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'space-evenly'}
			className={'videoplayer-lecture-item'}
			gap>
			<Typography
				variant='h5'>
				{
					new Date(lectureData.lectureDateTime).toDateString() + " " + getTime(lectureData.lectureDateTime)
				}
			</Typography>

			{
				lectureData.lectureIsHoliday
					?
					<Typography
						color={'#ffa500'}
						variant='h5'>
						Holiday
					</Typography>
					:
					<LectureDataButtons
						lecture={lectureData} />
			}

			<LectureAttendanceContainer
				lecture={lectureData} />

		</Box >
	)
}

export default LectureItem