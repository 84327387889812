
const getBreadcrumbName = (value) => {
	switch (value) {
		case 'dashboard':
			return ('Dashboard')

		case 'profile':
			return ('Profile')

		case 'all-courses':
			return ('All Courses')

		case 'offline-courses':
			return ('Offline Courses')

		case 'online-courses':
			return ('Online Courses')

		case 'ondemand-courses':
			return ('OnDemand Courses')

		case 'settings':
			return ('Settings')

		default:
			return ('')
	}
}

export default getBreadcrumbName;