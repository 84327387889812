import React from 'react'

import './ReviewItem.css';
import { Card, Rating } from '@mui/material';

export const ReviewItem = ({ review }) => {
	return (
		<div
			className='review-item'>
			<Card
				sx={{
					width: '100%',
					aspectRatio: '1'
				}}>

				<h2
					className='review-item-content review-item-name'>
					{review.name}
				</h2>
				<Rating
					className='review-item-content review-item-rating'
					name='read-only'
					readOnly
					value={review.rating}
				/>
				<span className='review-item-content review-item-message'>{review.message}</span>

			</Card>
		</div >
	)
}
