import './App.css';

import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

// import { CourseDetails } from './Components/CourseDetails/CourseDetails';
import { CoursePage } from './Pages/AllCoursesPage/CoursePage';
import { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { firestore } from './Components/Common/firebase';
import { AboutUsPage } from './Components/AboutUsPage/AboutUsPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { UserCoursesPage } from './Pages/UserCoursesPage/UserCoursesPage';
// import { EnrolledCoursesContainer } from './Pages/UserCoursesPage/EnrolledCoursesContainer';
import { MainPage } from './Pages/MainPage/MainPage';
import { AppBarComponent } from './Pages/MainPage/Components/AppBarComponent/AppBarComponent';
import { RegistrationFormPage } from './Pages/RegisterationFormPage/RegistrationFormPage';
import ReactGA from './ga';
import User from './Pages/User/User';
import Dashboard from './Pages/User/Components/Dashboard/Dashboard';
import Profile from './Pages/User/Components/Profile/Profile';
import EnrolledCourses from './Pages/User/Components/EnrolledCourses/EnrolledCourses';
import EnrolledCoursesList from './Pages/User/Components/EnrolledCourses/EnrolledCoursesList';
import { LoadingPage } from './Components/Common/LoadingPage';
import CourseBatch from './Pages/User/Components/CourseBatch/CourseBatch';
import ParticleBackground from './Components/ParticleBackground/ParticleBackground';
import { Footer } from './Components/Common/Footer';
import CourseDetailsPage from './Pages/CourseDetailesPage/CourseDetailsPage';
import Settings from './Pages/User/Components/Settings/Settings';

function usePageViews() {
	let location = useLocation();
	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
	}, [location]);
}

function RouterListener() {
	usePageViews();
	return (null);
}

function App() {

	const [user, setUser] = useState(null);
	const [userData, setUserData] = useState(null);
	const [courseKeys, setCourseKeys] = useState([]);
	const [coursesData, setCoursesData] = useState(null);

	useEffect(() => {

		getDocs(collection(firestore, 'Courses'))
			.then((allDocs) => {
				setCourseKeys([]);
				setCoursesData([]);
				allDocs.forEach((doc) => {
					setCourseKeys(data => {
						return (
							[
								...data,
								doc.id
							]
						)
					})

					setCoursesData(data => {
						return (
							{
								...data,
								[doc.id]: (doc.data()[doc.data().currentBatch])
							}
						)
					})
				})
			})

		const loggedInUser = JSON.parse(localStorage.getItem('user'));

		if (loggedInUser) {
			setUser(JSON.parse(localStorage.getItem('user')));
			getDoc(doc(firestore, 'Users/' + loggedInUser.id))
				.then((docSnapshot) => {
					localStorage.setItem('userdata', JSON.stringify(docSnapshot.data()))
					setUserData(docSnapshot.data());
				});
		}

	}, []);

	if (null === coursesData) {
		return (
			<LoadingPage />
		)
	}

	return (
		<BrowserRouter>
			<RouterListener />
			<GoogleOAuthProvider
				clientId='851777582489-unsfve0umc180bnspc985kdborn51vmc.apps.googleusercontent.com'>
				<AppBarComponent
					user={user}
					setUser={setUser} />
			</GoogleOAuthProvider>

			<Routes>
				<Route path='/' element={<MainPage />} />
				<Route path='/register-to-session' element={<RegistrationFormPage />} />
				<Route path='courses' element={<CoursePage />} />
				{
					courseKeys.map((key, index) => {
						return (
							<Route
								key={index}
								path={'course+details/' + key}
								element={
									<CourseDetailsPage
										courseData={coursesData[key]} />
								} />
						)
					})
				}
				<Route path='about-us' element={<AboutUsPage />} />

				<Route path='user' element={<User />} >
					<Route
						path='dashboard'
						element={<Dashboard
							user={user}
						/>} />
					<Route
						path='profile'
						element={<Profile
							user={user} />} />
					<Route
						path='enrolled-courses'
						element={<EnrolledCourses
							user={user}
							coursesData={coursesData} />} >
						<Route
							path='all-courses'
							element={
								<EnrolledCoursesList
									filter={1}
									user={user}
									coursesData={coursesData} />
							} />
						<Route
							path='offline-courses'
							element={
								<EnrolledCoursesList
									filter={2}
									user={user}
									coursesData={coursesData} />
							} />
						<Route
							path='online-courses'
							element={
								<EnrolledCoursesList
									filter={3}
									user={user}
									coursesData={coursesData} />
							} />
						<Route
							path='ondemand-courses'
							element={
								<EnrolledCoursesList
									filter={4}
									user={user}
									coursesData={coursesData} />
							} />
					</Route>
					<Route
						path='settings'
						element={<Settings user={userData} />} />

				</Route>
				{
					Object.keys(coursesData).map((key, index) => {
						return (
							<Route
								key={index}
								path={'user/' + key}
								element={
									<CourseBatch
										user={user}
										batch={coursesData[key]} />
								} />
						)
					})
				}
			</Routes>

			<Footer />
			<ParticleBackground />

		</BrowserRouter >
	);
}

export default App;
