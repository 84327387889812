import React, { useState } from 'react'

import './Settings.css';

import { Box, Divider, Typography } from '@mui/material'
import SettingsTabs from './Components/SettingTabs/SettingsTabs';
import ProfileSettingsPseudoRouter from './Components/ProfileSettingsPseudoRouter/ProfileSettingsPseudoRouter';

const Settings = ({ user }) => {

	// console.log(user);
	const [tabValue, setTabValue] = useState(1);

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			className='settings-wrapper'>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='space-evenly'
				width='100%'
				className='settings-container'
				gap={'10px'}>

				<Typography
					variant='h4'
					sx={{
						padding: '10px'
					}}>
					Settings
				</Typography>

				<Divider
					sx={{
						borderColor: '#888',
						borderWidth: '1px',
						width: '100%',
						alignSelf: 'stretch'
					}} />

				<SettingsTabs
					tabValue={tabValue}
					setTabValue={setTabValue} />

				<Divider
					sx={{
						borderColor: '#888',
						borderWidth: '1px',
						width: '100%',
						alignSelf: 'stretch'
					}} />

				<ProfileSettingsPseudoRouter
					tabValue={tabValue}
					userData={user} />

			</Box >

		</Box >
	)
}

export default Settings